/**
 * Created by rvvslv on 13.01.21.
 */

var MenuBarWithTimeoutView = MenuBarItemView.extend({
    ctor: function (item) {
        this._super(item);

        this.item.onChangeTimer = this.createListener(this.onChangeTimer.bind(this));
        this.item.onRefreshTimer = this.createListener(this.onRefreshTimer.bind(this));
    },

    onChangeTimer: function (silent) {
        if (this.timer) {
            if (this.timer instanceof cleverapps.UI.ImageFont && this.item.timerTTF) {
                this.removeTimer(silent);
            }
            if (!(this.timer instanceof cleverapps.UI.ImageFont) && !this.item.timerTTF) {
                this.removeTimer(silent);
            }
        }

        if (this.item.timer) {
            this.createTimer(silent);
        } else {
            this.removeTimer(silent);
        }
    },

    reset: function (silent) {
        this._super(silent);
        this.timerBlock = undefined;
        this.onChangeTimer(true);
    },

    createTimer: function (silent) {
        if (this.timerBlock || connector.platform.oneOf(connector.DIGITAL, connector.DIGITALWEB)) {
            return;
        }

        this.timerBlock = cleverapps.UI.createScale9Sprite(bundles.menubar.frames.bar_timer_png);
        this.timerBlock.setCascadeOpacityEnabled(true);
        var width = this.bg.width - 2 * this.styles.timer.padding.x;
        this.timerBlock.setContentSize2(width, this.styles.timer.height);
        this.timerBlock.setPositionRound(this.styles.timer.position);
        this.addChild(this.timerBlock, -1);

        if (this.styles.timer.icon) {
            var icon = new cc.Sprite(bundles.timer.frames.timer_png);
            icon.setPositionRound(this.styles.timer.icon);
            this.timerBlock.addChild(icon);
        }

        if (this.item.timerTTF) {
            this.timer = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.MENUBAR_TIMER_TEXT);
        } else {
            this.timer = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.MENUBAR_TIMER_TEXT, { fixedNumberWidth: true });
        }
        this.timer.baseScale = 1;
        this.timerBlock.addChild(this.timer);

        if (silent) {
            this.onRefreshTimer();
        } else {
            this.timerBlock.setOpacity(0);
            this.timerBlock.runAction(new cc.Sequence(
                new cc.FadeIn(0.3),
                new cc.CallFunc(this.onRefreshTimer.bind(this))
            ));
        }
    },

    removeTimer: function (silent) {
        if (!this.timerBlock) {
            return;
        }

        if (silent) {
            this.timerBlock.removeFromParent();
        } else {
            this.timerBlock.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.RemoveSelf()
            ));
        }
        this.timerBlock = undefined;
    },

    onRefreshTimer: function () {
        if (!this.timerBlock || this.item.getTimerString() === this.timer.getString()) {
            return;
        }

        this.timer.setString(this.item.getTimerString());
        this.timer.setFont(cleverapps.styles.FONTS.MENUBAR_TIMER_TEXT);
        this.timer.setScale(this.timer.baseScale);

        this.timer.fitTo(cleverapps.styles.MenuBarItem.timer.text.width);
        this.timer.setPositionRound(this.styles.timer.text);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MENUBAR_TIMER_TEXT: {
        name: "default",
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    timer: {
        icon: {
            x: { align: "left", dx: 31 },
            y: { align: "center", dy: 1 }
        },

        text: {
            x: { align: "left", dx: 68 },
            y: { align: "center", dy: 0 },
            width: 125
        },

        position: {
            x: { align: "center", dx: 16 },
            y: { align: "bottom", dy: -49 }
        },

        padding: {
            x: 40
        },
        height: 59
    }
});
