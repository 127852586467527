/**
 * Created by r4zi4l on 28.07.2022
 */

var Snapshots = function () {
    var delta = cleverapps.dataLoader.load(DataLoader.TYPES.SNAPSHOT_TIME_DELTA);
    DateShift.setShift(delta);

    if (delta) {
        var userData = cleverapps.user.getInfo();
        userData.forcedVisited = userData.visited + delta;
        delete userData.visited;
        cleverapps.user.updateInfo(userData);
    }
};

Snapshots.prototype.reset = function () {
    DateShift.setShift(0);
    cleverapps.dataLoader.remove(DataLoader.TYPES.SNAPSHOT_TIME_DELTA);
};

Snapshots.prototype.extractData = function (callback) {
    var waiter = cleverapps.wait(2, function () {
        var extractors = {};
        for (var type in CustomSyncers.extractors) {
            if (type.indexOf("units_") === 0) {
                continue;
            }

            extractors[type] = CustomSyncers.extractors[type];
        }

        for (type in Snapshots.extractors) {
            extractors[type] = Snapshots.extractors[type];
        }

        var data = {};
        data.date = Date.now();
        data.slot = Game.currentGame && Game.currentGame.slot;

        for (type in extractors) {
            var extractor = extractors[type];
            if (!extractor) {
                continue;
            }

            if (extractor.useSlots) {
                if (data.slot) {
                    data[type] = extractor(data.slot);
                }
            } else {
                data[type] = extractor();
            }
        }

        callback(data);
    });

    if (cleverapps.userClan) {
        cleverapps.userClan.load(waiter, waiter, {
            ignoreNoRest: true
        });
        cleverapps.clanMessages.loadMessages(waiter);
    } else {
        waiter();
        waiter();
    }
};

Snapshots.prototype.importData = function (snapshot, callback) {
    // migration from local checkpoints
    if (snapshot.data) {
        Object.keys(snapshot.data).forEach(function (key) {
            var tokens = key.split("_");

            if (tokens[0] !== "units") {
                return;
            }

            var code = tokens[1];
            var slot = tokens[2] || "";

            if (!snapshot.data["units" + slot]) {
                snapshot.data["units" + slot] = {};
            }

            snapshot.data["units" + slot][code] = snapshot.data[key];
            delete snapshot.data[key];
        });

        snapshot = snapshot.data;
    }

    // migration from old snapshots
    if (snapshot.sync) {
        snapshot.sync.date = snapshot.date;
        snapshot.sync.actions = snapshot.actions;
        snapshot.sync.settings = snapshot.settings;
        snapshot = snapshot.sync;
    }

    if (snapshot.missions) {
        snapshot.missions.forEach(function (mission) {
            if (mission.type === 55) {
                mission.type = Mission.TYPE_EASTER_EXPEDITION;
            }
        });
    }

    if (snapshot.scene && snapshot.scene.episodeNo === "undersea2") {
        snapshot.scene.episodeNo = "undersea";
        snapshot.scene.levelNo = 1;
    }

    // migrate from levels to locations
    if (snapshot.scene && snapshot.scene.levelNo && cleverapps.config.type === "merge") {
        snapshot.scene.episodeNo += snapshot.scene.levelNo + 1;
        snapshot.scene.levelNo = 0;
    }

    // migrate to units library with slots
    if (snapshot.unitslibrary && !snapshot.unitslibrary1) {
        snapshot.unitslibrary1 = {};
        snapshot.unitslibrary2 = {};
        snapshot.unitslibrary3 = {};
        snapshot.unitslibrary4 = {};
    }

    cleverapps.snapshots.reset();

    if (cleverapps.localPushes) {
        cleverapps.localPushes.reset();
    }

    if (cleverapps.unitSavers) {
        cleverapps.unitSavers.reset();
    }

    if (cleverapps.GameSaver) {
        cleverapps.GameSaver.reset();
    }

    snapshot.achievements = snapshot.achievements || 0;
    snapshot.energyProducts = snapshot.energyProducts || {};
    snapshot.adsLimits = snapshot.adsLimits || {};

    if (snapshot.date) {
        var delta = snapshot.date - Date.now();
        DateShift.setShift(delta);
        cleverapps.dataLoader.save(DataLoader.TYPES.SNAPSHOT_TIME_DELTA, delta);
    }
    if (!snapshot.abtest) {
        cleverapps.dataLoader.remove(DataLoader.TYPES.ABTEST);
    }

    var type;
    var importers = {};

    for (type in Snapshots.importers) {
        importers[type] = Snapshots.importers[type];
    }

    for (type in CustomSyncers.importerData) {
        if (!importers[type]) {
            importers[type] = CustomSyncers.importerData[type];
        }
    }

    if (!cleverapps.config.importMode) {
        snapshot.users.id = undefined;
    } else if (snapshot.users.id) {
        connector.platform.setUserID(snapshot.users.id);

        if (connector.social.oneOf(connector.TEST) && snapshot.isLoggedIn) {
            connector.social.getPlugin().updateSession(snapshot.users.id);
        }
    }

    for (type in importers) {
        if (type in snapshot) {
            var importer = importers[type];
            var data = cleverapps.clone(snapshot[type], true);

            if (importer.useSlots) {
                if (snapshot.slot) {
                    importer(snapshot.slot, data);
                }
            } else {
                importer(data);
            }
        }
    }

    cleverapps.travelBook.init();

    Messages.preload(cleverapps.settings.language, callback);
};

Snapshots.prototype.canCreate = function () {
    var environment = cleverapps.environment;
    if (environment.isEditorScene() || environment.isAdministratorScene()
        || environment.isWysiwygScene() || environment.isAtlasAnalyzerScene()) {
        cleverapps.notification.create("Can't take snapshot on current scene");
        return false;
    }

    if (Game.currentGame && Game.currentGame.outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.notification.create("Can't take snapshot if outcome is victory");
        return false;
    }

    return true;
};

Snapshots.prototype.createSnapshot = function (data, onSuccess) {
    if (!this.canCreate()) {
        return;
    }

    onSuccess = onSuccess || function (targetUrl) {
        cleverapps.copyToClipboard(targetUrl, function () {
            cleverapps.notification.create("snapshot copied to clipboard");
        });
        this.openInNewTab(targetUrl);
    }.bind(this);

    var create = function (data) {
        var id = Math.random().toString(36).slice(2);
        var restUrl = cleverapps.RestClient.standsRest("/snapshots/create/" + id);
        var targetUrl = cleverapps.RestClient.standsWeb("/" + id);

        cleverapps.RestClient.post(restUrl, data, function () {
            onSuccess(targetUrl);
        }, function (error) {
            console.log("connection error", error);
            cleverapps.notification.create(error.errorMessage || error.statusText || error);
        }, {
            ignoreNoRest: true
        });
    };

    if (Game.currentGame && Game.currentGame.introPlaying) {
        create(Game.currentGame.introData);
        return;
    }

    if (data) {
        create(data);
    } else {
        this.extractData(create);
    }
};

Snapshots.prototype.openInNewTab = function (url) {
    connector.platform.openUrl(url);
};

Snapshots.prototype.getUrlById = function (id) {
    return cleverapps.RestClient.standsRest("/snapshots/get/" + id + ".json");
};

Snapshots.prototype.getSnapshot = function (id, callback) {
    cc.loader.load(
        this.getUrlById(id),
        function (error, response) {
            if (error) {
                cleverapps.notification.create(response.errorMessage || response.statusText || response || "no snapshot found");
            }
            callback(response[0]);
        }
    );
};

Snapshots.prototype.exportToJson = function (snapshot) {
    var element = document.createElement("a");
    element.href = URL.createObjectURL(new Blob([JSON.stringify(snapshot, null, 4)], {
        type: "application/json"
    }));
    element.setAttribute("download", cleverapps.config.name + "_" + cleverapps.user.getHumanReadableNumber() + ".json");
    element.style.display = "none";

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

Snapshots.prototype.importFromUrl = function (callback) {
    var url = prompt("enter snapshot url");

    var id = url && url.replace(/.*snapshot=([A-Za-z0-9]+).*/, "$1");
    if (id === url) {
        id = url && url.replace(/.*labsystech.ru\/([A-Za-z0-9]+).*/, "$1");
    }
    if (!id) {
        return;
    }

    this.getSnapshot(id, function (snapshot) {
        callback(snapshot);
    });
};

Snapshots.prototype.record = function () {
    this.extractData(function (data) {
        cleverapps.capturedSnapshot = data;
        cleverapps.actionsRecording = new CapturedScenario();

        PointerHandlerExtensions.in();
    });
};

Snapshots.prototype.stopRecording = function () {
    cleverapps.actionsRecording.pushAction({
        type: "finish"
    });

    PointerHandlerExtensions.out();

    var data = cleverapps.capturedSnapshot;
    data.actions = cleverapps.actionsRecording.steps;

    delete cleverapps.capturedSnapshot;
    delete cleverapps.actionsRecording;
    this.createSnapshot(data);
};

Snapshots.prototype.playActions = function (actions) {
    cleverapps.snapshotBar.hideButtons();
    cleverapps.snapshotBar.showLabel("**playback");

    console.log("actions: ", actions);

    new GamePlayer({
        finger: {
            image: bundles.finger.frames.tutorial_hand_png
        }
    }).play({
        actions: actions,
        delay: 0,
        initialDelay: 0
    }, function () {
        cleverapps.snapshotBar.removeLabel();
        cleverapps.snapshotBar.showButtons();
    });
};
