/**
 * Created by andrey on 20.08.2021.
 */

var RewardedAdOverlay = cc.Scale9Sprite.extend({
    ctor: function (name) {
        this._super(bundles.pixel.frames.pixel_png);

        this.alwaysOn = true;

        this.setColor(cleverapps.styles.COLORS.BLACK);
        this.setOpacity(80);
        this.setLocalZOrder(Transition.ZORDER + 1);

        cleverapps.UI.onClick(this, function () {
            if (!this.closeText.isVisible()) {
                return;
            }

            var cancelled = connector.platform.oneOf(connector.YANDEX, connector.GDCOM, connector.COOLMATHGAMES, connector.FACEBOOK, connector.TEST);
            connector.ads.finishAd(name, name === RewardedAdsManager.REWARDED && !cancelled ? connector.CODE_SUCCEED : "closed_by_user");
        }.bind(this), {
            interactiveScale: false
        });

        this.addLoadText();
        this.addCloseText();
        this.setupChildren();
    },

    setupChildren: function () {
        var bgSize = cleverapps.resolution.getBgSize();
        var safePadding = cleverapps.resolution.getSafePadding();

        this.setContentSize2(bgSize.width + 4, bgSize.height + 4);
        this.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);
        this.setAnchorPoint(0.5, 0.5);

        if (this.loadText) {
            this.loadText.setPositionRound(cleverapps.styles.RewardedAdOverlay.text);
        }

        if (this.closeText) {
            this.closeText.setDimensions(bgSize.width, 0);
            this.closeText.fitTo(bgSize.width);
            this.closeText.setPositionRound(cleverapps.styles.RewardedAdOverlay.text);
        }
    },

    addLoadText: function () {
        var loadText = this.loadText = cleverapps.UI.generateOnlyText("Loading", cleverapps.styles.FONTS.REWARDED_AD_OVERLAY_TEXT);
        loadText.setPositionRound(cleverapps.styles.RewardedAdOverlay.text);
        this.addChild(loadText);
        loadText.setOpacity(0);

        loadText.runAction(new cc.Sequence(
            new cc.FadeIn(0.3),
            new cc.DelayTime(RewardedAdOverlay.CLOSE_AVAILABLE / 1000 - 0.6),
            new cc.FadeOut(0.3),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                delete this.loadText;
            }.bind(this))
        ));
    },

    addCloseText: function () {
        var closeText = this.closeText = cleverapps.UI.generateOnlyText("Window.TapToContinue", cleverapps.styles.FONTS.REWARDED_AD_OVERLAY_TEXT);
        closeText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        closeText.setDimensions(cleverapps.resolution.getSceneSize().width, 0);
        closeText.fitTo(cleverapps.resolution.getSceneSize().width);
        closeText.setPositionRound(cleverapps.styles.RewardedAdOverlay.text);
        closeText.visible = false;
        closeText.setOpacity(0);
        this.addChild(closeText);

        closeText.runAction(new cc.Sequence(
            new cc.DelayTime(RewardedAdOverlay.CLOSE_AVAILABLE / 1000),
            new cc.Show(),
            new cc.FadeIn(0.3)
        ));
    }
});

RewardedAdOverlay.initialize = function () {
    var closeAvailable = "5 seconds";

    if (connector.platform.oneOf(connector.GDCOM, connector.COOLMATHGAMES)) {
        closeAvailable = "15 seconds";
    }

    RewardedAdOverlay.CLOSE_AVAILABLE = cleverapps.parseInterval(closeAvailable);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    REWARDED_AD_OVERLAY_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.RewardedAdOverlay = {
    text: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
