/**
 * Created by mac on 12/1/24
 */

var Restorable = function (options) {
    this.options = options || {};
};

Restorable.prototype.waitGoodMoment = function (product, context) {
    var required = this.requiredLocation(product, context);
    var location = Restorable.GetVirtualLocation();

    return required && Restorable.IsActiveLocation(required) && location !== required;
};

Restorable.GetVirtualLocation = function () {
    if (cleverapps.travelBook.isExpedition()) {
        return cleverapps.travelBook.getCurrentPage().id;
    }

    return cleverapps.environment.getScene();
};

Restorable.IsActiveLocation = function (location) {
    if (cleverapps.config.type === "merge") {
        var page = cleverapps.travelBook.getPageById(location);
        if (page) {
            return page.isActive() && !page.isCompleted();
        }
    }

    return true;
};

Restorable.prototype.requiredLocation = function (product, context) {
    var location = this.options.location;

    if (typeof location === "function") {
        location = location(product, context);
    }

    return location;
};

Restorable.prototype.listActions = function (product, context, consume) {
    var listActions = this.options.listActions;
    return listActions && listActions(product, context, consume) || Restorable.DefaultActions(product, context, consume);
};

Restorable.DefaultActions = function (product, context, consume) {
    return [
        function (f) {
            consume();

            var reward = product.restoreReward || product.reward;
            if (reward) {
                RewardWindow.createPurchaseWindow(reward);
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        }
    ];
};

Restorable.LocationContextExpedition = function (product, context) {
    return context.expedition || "main";
};

Restorable.LocationOfferExpedition = function (product, context) {
    return cleverapps.offerManager.getOfferExpedition(context.offer);
};

Restorable.BY_TYPE = {};

Restorable.BY_TYPE.default = new Restorable();