/**
 * Created by Andrey Popov on 6/3/21
 */

var MenuBar = function () {
    this.items = MenuBar.listItems().map(function (itemConfig) {
        var ModelClass = itemConfig.modelClass;
        return itemConfig.modelClass ? new ModelClass(itemConfig) : new MenuBarItem(itemConfig);
    });
};

MenuBar.listItems = function () {
    if (cleverapps.config.demoMode) {
        return [];
    }

    var items = [];

    if (typeof GameLevelMenuBarItem !== "undefined") {
        items.push(GameLevelMenuBarItem);
    }

    if (["klondike"].indexOf(cleverapps.config.type) === -1) {
        items.push(GoldMenuBarItem);
    }

    if (typeof LivesMenuBarItem !== "undefined") {
        items.push(LivesMenuBarItem);
    }

    if (cleverapps.config.soft) {
        items.push(SoftMenuBarItem);
    }

    if (typeof WandsMenuBarItem !== "undefined") {
        items.push(WandsMenuBarItem);
    }

    if (cleverapps.config.name === "hearts") {
        items.push(HammersMenuBarItem);
    } else if (typeof WorkersMenuBarItem !== "undefined") {
        items.push(WorkersMenuBarItem);
    }

    if (typeof MethaStarsMenuBarItem !== "undefined") {
        items.push(MethaStarsMenuBarItem);
    }

    if (typeof ExpMenuBarItem !== "undefined") {
        items.push(ExpMenuBarItem);
    }

    if (typeof CryptexMenuBarItem !== "undefined") {
        items.push(CryptexMenuBarItem);
    }

    return items;
};

MenuBar.prototype.getSceneItems = function () {
    if (cleverapps.environment.isEditorScene()) {
        return [];
    }

    var items = [];
    this.items.forEach(function (item) {
        if (item.isAvailableOnScene()) {
            item.init();
            items.push(item);
        } else {
            item.clean();
        }
    });
    return items;
};

MenuBar.prototype.stopItems = function () {
    this.items.forEach(function (item) {
        item.stop();
    });
};

MenuBar.prototype.showForce = function (name) {
    var result;

    var item = this.items.find(function (item) {
        return item.name === name;
    });

    cleverapps.focusManager.display({
        focus: name + "MenuBarItemForce",
        control: "MenuBar" + name,
        actions: [
            function (f) {
                result = true;
                cleverapps.timeouts.setTimeout(f, 500);
            },

            function (f) {
                item.onShowForce(item.config.force);
                
                cleverapps.forces.onceForceClosed = f;
            }
        ]
    });

    return result;
};

MenuBar.prototype.clean = function () {
    this.items.forEach(function (item) {
        item.clean();
    });
};