/**
 * Created by Vladislav on 20.09.2024.
 */

cleverapps.Tool.UI.windows.miniGameWindow = displayToolWindow(function () {
    cleverapps.timeouts.setTimeout(function () {
        cleverapps.miniGame.unlock();
        var reward = cleverapps.clone(cleverapps.miniGame.getReward(), true);
        new MiniGameWindow(reward);
    }, 0);
});

cleverapps.Tool.UI.rewardWindows.miniGameRewardWindow = displayToolWindow(function () {
    RewardWindow.createMiniGameWindow(cleverapps.clone(cleverapps.miniGame.getReward(), true));
});