/**
 * Created by olga on 14.11.2024
 */

var BaseLocation = function (locationId) {
    cleverapps.EventEmitter.call(this);

    this.locationId = locationId;

    var stored = cleverapps.meta.loadLocation(this.getSlotId());
    this.load(stored);
};

BaseLocation.prototype = Object.create(cleverapps.EventEmitter.prototype);
BaseLocation.prototype.constructor = Location;

BaseLocation.prototype.load = function () {
};

BaseLocation.prototype.getInfo = function () {
    return undefined;
};

BaseLocation.prototype.save = function () {
    var info = this.getInfo();
    if (info) {
        cleverapps.meta.saveLocation(this.getSlotId(), info);
    }
};

BaseLocation.prototype.getSlotId = function () {
    return undefined;
};

BaseLocation.prototype.checkCompleted = function () {
    return false;
};

BaseLocation.prototype.onComplete = function (f) {
    f();
};

BaseLocation.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        game.rewards.metaStar = this.amountLevelStars(game.level);
    }
};

BaseLocation.prototype.amountLevelStars = function () {
    return 0;
};

BaseLocation.prototype.isCurrentLocation = function () {
    return this.locationId === cleverapps.meta.selectedLocationId();
};

BaseLocation.prototype.processChatMessage = function () {
};
