/**
 * Created by andrey on 22.08.17.
 */

var ConfirmExitWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};
        this.action = options.action || function () {};

        var styles = this.styles = cleverapps.styles.ConfirmExitWindow[options.source] || cleverapps.styles.ConfirmExitWindow;

        var text = this.createText(options);

        var view, spine = options.spine || bundles.confirmexit_window.jsons.confirmexit_window_json;
        if (spine) {
            view = this.createAnimation(spine, options.animationName);
        }

        if (options.reward) {
            var rewardList = new RewardsListComponent(options.reward, {
                font: cleverapps.styles.FONTS.WINDOW_TEXT,
                margin: styles.reward.margin
            });
        }

        if (typeof BreakViews !== "undefined" && Game.currentGame && cleverapps.environment.isGameScene()) {
            var breaks = new BreakViews();
        }

        var content = new cleverapps.Layout([view, text, breaks, rewardList], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: options.padding || styles.padding
        });

        this._super({
            closeByBackButton: true,
            title: "ConfirmExitWindow.title",
            name: "confirmexitwindow",
            content: content,
            buttons: this.getButtons(),
            minWidth: styles.size ? styles.size.width : undefined,
            minHeight: styles.size ? styles.size.height : undefined
        });

        if (styles.button.exitButtonPosition) {
            this.exitGameButton.setPositionRound(styles.button.exitButtonPosition);
        }

        if (styles.button.playButtonPosition) {
            this.playButton.setPositionRound(styles.button.playButtonPosition);
        }

        if (styles.content) {
            this.content.setPositionRound(styles.content);
        }
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "sad"
        };
    },

    createAnimation: function (spine, animationName) {
        var styles = this.styles.view;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize(styles);

        var animation = new cleverapps.Spine(spine);
        node.addChild(animation);

        if (animationName) {
            animation.setAnimation(0, animationName, true);
        } else if (animation.hasAnimation("open")) {
            animation.setAnimationAndIdleAfter("open", "idle");
        } else {
            animation.setAnimation(0, "sad", true);
        }

        animation.setPositionRound(styles);

        return node;
    },

    createText: function (options) {
        var styles = this.styles;

        var message = options.text || "AreYouSure";
        if (cleverapps.environment.isGameScene() && cleverapps.config.type !== "merge") {
            if (Game.currentGame.useLives()) {
                message = "ConfirmExitWindow.message";
            }
            if (Game.currentGame.level.isBonusRound()) {
                message = "ConfirmExitWindow.BonusRound.message";
            }
        }

        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setAnchorPoint(0.5, 0.5);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.text.height);

        return text;
    },

    getButtons: function () {
        var styles = this.styles;

        var button = this.playButton = new cleverapps.UI.Button({
            text: "PlayOn",
            onClicked: function () {
                this.close();
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });

        var exitButton = this.exitGameButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: "Exit",
            onClicked: function () {
                this.closeAnimationCallbacks.push(this.action);
                this.close();
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });

        return [exitButton, button];
    },

    listBundles: function () {
        return ["confirmexit_window"];
    }
});

ConfirmExitWindow.SOURCES = {};

ConfirmExitWindow.SOURCES.OCTOPUS_HUNT = 1;

cleverapps.styles.ConfirmExitWindow = {
    margin: 20,

    padding: {
        bottom: 40
    },

    view: {
        width: 400,
        height: 400,

        x: { align: "center" },
        y: { align: "center" }
    },

    text: {
        width: 500
    },

    button: {
        width: 300,
        height: 100
    },

    reward: {
        margin: {
            x: 100
        }
    }
};

cleverapps.styles.ConfirmExitWindow[ConfirmExitWindow.SOURCES.OCTOPUS_HUNT] = cleverapps.overrideStyles(cleverapps.styles.ConfirmExitWindow, {
    size: {
        width: 815,
        height: 940
    },
    button: {
        width: 295,
        height: 95,
        exitButtonPosition: {
            x: {
                align: "right",
                dx: -51
            },
            y: {
                align: "bottom",
                dy: 0
            }
        },
        playButtonPosition: {
            x: {
                align: "left",
                dx: 51
            },
            y: {
                align: "bottom",
                dy: 0
            }
        }
    },
    view: {
        x: {
            align: "center"
        },
        y: {
            align: "center",
            dy: 6
        }
    },
    margin: 49,
    content: {
        x: {
            align: "center"
        },
        y: {
            align: "top",
            dy: -166
        }
    }
}, true);
