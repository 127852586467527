/**
 * Created by Aleksandr on 06.05.2024
 */

Mission.TYPE_PIGGY_BANK = 65;

Missions.FEATURES[Mission.TYPE_PIGGY_BANK] = function () {
    Missions[Mission.TYPE_PIGGY_BANK] = {
        available: {
            level: cleverapps.config.type === "board" ? 3.2 : 3.6,
            payments: true,
            youtube: {
                disabled: true
            }
        },
        name: "PiggyBank",
        view: PiggyBankWindow,
        startWindow: PiggyBankWindow,
        semaphore: Missions.SEMAPHORE_CUSTOM_PIGGY_BANK,
        duration: "2 days",
        sideBarJson: bundles.sidebar.jsons.piggybank_icon_json,
        finishWindow: PiggyBankWindow,
        logic: PiggyBankMissionLogic
    };
};