/**
 * Created by olga on 23.10.2024
 */

var Meta = function () {
    cleverapps.EventEmitter.call(this);
};

Meta.prototype = Object.create(cleverapps.EventEmitter.prototype);
Meta.prototype.constructor = Meta;

Meta.prototype.load = function () {
    var type = this.getType();
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.META);
    var needSave = false;

    if (!data) {
        if (type === Meta.FARM) {
            data = cleverapps.dataLoader.load(DataLoader.TYPES.FARM);
        }

        if (type === Meta.HOMEFIX) {
            data = cleverapps.dataLoader.load(DataLoader.TYPES.HOMEFIX);
        }

        if (type === Meta.SHORTMETA) {
            data = cleverapps.dataLoader.load(DataLoader.TYPES.SHORTMETA) || cleverapps.dataLoader.load(DataLoader.TYPES.KNOCKOUT);
        }

        if (type === Meta.SIMPLE) {
            if (Version.compare(cleverapps.user.lastVersion, Meta.SIMPLE_MIGRATE_VERSION) < 0) {
                data = cleverapps.dataLoader.load(DataLoader.TYPES.SIMPLE);
                needSave = true;
            }
        }
    }

    // remove old key for backward compatability (old client will run migration from user.episode & user.level again)
    if (type === Meta.HOSE) {
        cleverapps.dataLoader.remove(DataLoader.TYPES.HOSE);
    }

    this.updateData(data);
    this.resetActiveLocations();
    this.switchLocation(this.currentLocationId);

    if (needSave) {
        this.save();
    }

    cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.currentLocationId);
};

Meta.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getData());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

Meta.prototype.setCurrentLocation = function (locationId) {
    this.currentLocationId = locationId;
    this.currentLocationStars = 0;
    this.resetActiveLocations();
    this.switchLocation(this.currentLocationId);
    this.save();
};

Meta.prototype.switchLocation = function (locationId) {
    var activeLocations = this.listActiveLocations();
    if (activeLocations.indexOf(locationId) === -1) {
        if (activeLocations.length === 0) {
            throw "no locations to switch for " + this.currentLocationId;
        }

        locationId = activeLocations[activeLocations.length - 1];
    }

    var LocationClass = this.getLocationClass();
    this.location = new LocationClass(locationId);

    if (this.getType() === Meta.HOSE) {
        this.currentLocationStars = this.location.getInfo().stars;
    }
};

Meta.prototype.getType = function () {
    if (connector.info.source === "playable") {
        return Meta.NONE;
    }

    return cleverapps.config.meta;
};

Meta.prototype.migrateData = function (data) {
    if (cleverapps.config.rpg && !data && levels.user.episode > 0) {
        data = {
            current: levels.user.episode,
            stars: 0
        };
    }

    var type = this.getType();
    if (type === Meta.HOSE) {
        data = data || {};

        if (data.current === undefined || data.stars === undefined) {
            data.current = levels.user.episode;
            data.stars = levels.user.level;
        }
    }

    return data || {};
};

Meta.prototype.updateData = function (data) {
    data = this.migrateData(data);

    this.currentLocationId = data.current || 0;
    this.stars = data.stars || 0;

    if (this.getType() === Meta.HOSE) {
        this.currentLocationId = data.current || 0;
        this.currentLocationStars = data.stars || 0;
        this.stars = 0;
    }

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.updateData(data);
    }
};

Meta.prototype.updateInfo = function (serverData) {
    this.updateData(serverData);
    this.resetActiveLocations();
    this.switchLocation(this.currentLocationId);
    this.save(true);

    if (cleverapps.highscore) {
        cleverapps.highscore.updateInfo(serverData);
        cleverapps.highscore.save(true);
    }
};

Meta.prototype.getData = function () {
    var data = {
        current: this.currentLocationId,
        stars: this.stars
    };

    if (this.getType() === Meta.HOSE) {
        data = {
            current: this.currentLocationId,
            stars: this.currentLocationStars
        };
    }

    if (cleverapps.knockoutGame) {
        Object.assign(data, cleverapps.knockoutGame.getData());
    }

    return data;
};

Meta.prototype.getInfo = function () {
    var data = this.getData();

    if (cleverapps.highscore) {
        Object.assign(data, cleverapps.highscore.getInfo());
    }

    return data;
};

Meta.prototype.loadLocation = function (slot) {
    if (this.getType() === Meta.HOSE) {
        return { stars: this.currentLocationStars };
    }

    return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
};

Meta.prototype.saveLocation = function (slot, data) {
    if (this.getType() === Meta.HOSE) {
        this.currentLocationStars = data.stars;
        this.save();
        return;
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, data);

    // cleverapps.synchronizer.addUpdateTask("location" + slot);
};

Meta.prototype.listAvailableLocations = function () {
    if (!this.availableLocationsCache) {
        this.resetAvailableLocations();
    }

    return this.availableLocationsCache;
};

Meta.prototype.resetAvailableLocations = function () {
    var LocationClass = this.getLocationClass();
    this.availableLocationsCache = LocationClass.ListAvailableLocations();
};

Meta.prototype.listActiveLocations = function () {
    if (!this.activeLocationsCache) {
        this.resetActiveLocations();
    }

    return this.activeLocationsCache;
};

Meta.prototype.resetActiveLocations = function () {
    var LocationClass = this.getLocationClass();
    this.activeLocationsCache = LocationClass.ListActiveLocations(this.currentLocationId);
};

Meta.prototype.getMainObject = function () {
    return this.location;
};

Meta.prototype.selectedLocationId = function () {
    return this.location.locationId;
};

Meta.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);
    }

    this.getMainObject().gamePlayed(outcome, game);
};

Meta.prototype.resetStars = function () {
    this.stars = 0;
    this.save();
};

Meta.prototype.changeStars = function (stars, silent) {
    this.stars += stars;
    this.save();
    if (!silent) {
        this.onChangeStars(stars);
    }
};

Meta.prototype.onChangeStars = function (stars) {
    this.trigger("changeStars", stars);
};

Meta.prototype.isCompleted = function () {
    return this.currentLocationId > this.getLastLocationId();
};

Meta.prototype.getNextLocationId = function (locationId) {
    var LocationClass = this.getLocationClass();
    return LocationClass.NextLocationId(locationId);
};

Meta.prototype.getLastLocationId = function () {
    var availableLocations = this.listAvailableLocations();
    return availableLocations[availableLocations.length - 1];
};

Meta.prototype.moveNextLocation = function () {
    this.setCurrentLocation(this.getNextLocationId(this.currentLocationId));
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var mainObject = this.getMainObject();

    if (this.getType() === Meta.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : mainObject.findBuildingForActiveItem() || cleverapps.Random.mathChoose(mainObject.listAvailableProvidesBuildings());

        level = MethaHelper.getCurrentLevel(building);
    } else {
        level = level || MethaHelper.getCurrentLevel();
    }

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.wantsToPlay(f, level);
    } else {
        MethaHelper.start(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    MethaHelper.start(f, level);
};

Meta.prototype.reset = function () {
    this.currentLocationId = 0;
    this.currentLocationStars = 0;
    this.stars = 0;
    this.resetActiveLocations();
    this.switchLocation(this.currentLocationId);
    this.save();
};

Meta.prototype.getLocationClass = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Meta.FARM:
            return Farm;

        case Meta.HOMEFIX:
            return Home;

        case Meta.FISHDOM:
            return Fishdom;

        case Meta.SHORTMETA:
            return ShortMeta;

        case Meta.SIMPLE:
            return Simple;

        case Meta.NONE:
            return NoMetha;

        default:
            return Hose;
    }
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Meta.FARM:
            return FarmScene;

        case Meta.HOMEFIX:
            return HomefixScene;

        case Meta.FISHDOM:
            return FishdomScene;

        case Meta.SHORTMETA:
            return ShortMetaScene;

        case Meta.SIMPLE:
            return SimpleMainScene;

        case Meta.NONE:
            return GameScene;

        default:
            return HoseScene;
    }
};

// CustomSyncers.registerBySlots("locations", function (slot) {
//     return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
// }, function (slot, serverData) {
//     cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, serverData);
// });

Meta.HOSE = "hose";
Meta.FARM = "farm";
Meta.HOMEFIX = "homefix";
Meta.SHORTMETA = "shortmeta";
Meta.SIMPLE = "simple";
Meta.NONE = "none";
Meta.FISHDOM = "fishdom";

Meta.SIMPLE_MIGRATE_VERSION = "1.408.1";

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}

if (cleverapps.config.meta === "homefix") {
    Meta.LOCATION_SLOT0 = "0";
    Meta.LOCATION_SLOT1 = "1";
    Meta.LOCATION_SLOT2 = "2";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.LOCATION_SLOT0, Meta.LOCATION_SLOT1, Meta.LOCATION_SLOT2];
}
if (cleverapps.config.debugMode) { // only before update
    Meta.SIMPLE_MIGRATE_VERSION = "1.405.33";
}