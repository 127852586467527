/**
 * Created by andrey on 10.08.18.
 */

var GameBase = function (level, options) {
    cleverapps.EventEmitter.call(this);

    Game.currentGame = this;

    this.levelContent = level.content;
    this.level = level;
    this.options = options || {};

    this.slot = cleverapps.GameSaver.getStoreSlot(this.level.episodeNo, this.level.levelNo);
    this.savedGame = this.loadSave();

    this.score = new Score(this.savedGame.score);
    this.moves = this.savedGame.moves || 0;
    this.timer = new GameTimer(this);
    this.counter = new Counter();
    this.actionsBefore = this.options.actionsBefore || [];

    if (this.level.isRegular() && this.level.isNew()) {
        this.primaryMission = cleverapps.missionManager.findBySemaphore(Missions.SEMAPHORE_PRIMARY);
    }

    if (this.level.isRegular() && this.level.isNew() && !this.hasDanger()) {
        var mission = cleverapps.missionManager.findBySemaphore(Missions.SEMAPHORE_SECONDARY);

        if (mission && [Mission.TYPE_RAINBOW, Mission.TYPE_COLLECT_MARK, Mission.TYPE_BURN_NEARBY, Mission.TYPE_LETTER, Mission.TYPE_BUTTERFLY].includes(mission.type)) {
            this.secondaryMission = mission;
        }
    }

    this.rewards = {};

    if (this.secondaryMission) {
        this.rewards[GameBase.REWARD_SECONDARY] = 0;
    }

    if (this.primaryMission) {
        this.rewards.mission = {
            missionType: this.primaryMission.type,
            amount: this.primaryMission.getLevelReward(this.level.getTag())
        };
    }

    if (this.level.isRegular() && this.level.isNew() && cleverapps.exp.isAvailable()) {
        this.rewards[GameBase.REWARD_EXP] = this.level.isHard() ? GameBase.HARD_LEVEL_EXP_PRIZE : GameBase.EXP_PRIZE;
    }

    if (this.level.isRegular() && cleverapps.stickersBook && cleverapps.stickersBook.isAvailable() && !cleverapps.stickersBook.isCompleted()) {
        this.rewards[GameBase.REWARD_STICKERS] = cleverapps.stickersBook.getLevelReward();
    }

    this.setBasicReward();

    this.rewards[GameBase.REWARD_HARD] = 0;

    if (this.level.isBonus() || this.level.isBonusRound()) {
        this.rewards[GameBase.REWARD_HARD] = GameBase.BONUS_REWARD;
    }

    if (this.level.isDanger()) {
        this.rewards[GameBase.REWARD_HARD] = GameBase.DANGER_REWARD;
    }

    var lantern = Lantern.Get();
    if (lantern && lantern.isActive(this.level)) {
        this.rewards.lantern = lantern.savedStreak >= Lantern.getMaxStreak() ? 0 : 1;
        lantern.onStart();
    }

    cleverapps.missionManager.getRunningMissions().forEach(function (mission) {
        if (mission.logic && mission.logic.addGameReward) {
            mission.logic.addGameReward();
        }
    });

    if (this.savedGame.rewards) {
        this.rewards = cleverapps.override(this.rewards, this.savedGame.rewards);
    }

    if (this.levelWithTutorial() && cleverapps.config.type !== "merge") {
        cleverapps.Random.seed(this.level.getHumanReadableNumber() - 1);
    } else {
        cleverapps.Random.randomSeed();
    }

    this.outcome = GameBase.OUTCOME_UNKNOWN;

    if (cleverapps.flyingAd) {
        cleverapps.flyingAd.setGame();
    }

    this.counter.turnOff();
    if (cleverapps.config.type !== "merge") {
        this.counter.registerStage(-1, this.begin.bind(this));
    }

    this.boatswain = new Boatswain(level);

    this.counter.registerStage(1000, this.animateOutcome.bind(this));

    if (typeof Prolongation !== "undefined") {
        this.prolongation = new Prolongation();
        this.prolongation.on("acceptOffer", this.onAcceptProlongationOffer.bind(this), this);
    }

    if (cleverapps.lives && cleverapps.lives.amount > cleverapps.lives.getMaxLives()) {
        this.moreThenMaxRegenLife = true;
    }
    if (cleverapps.config.type !== "merge") {
        this.takeLife(true);
    }
};

GameBase.prototype = Object.create(cleverapps.EventEmitter.prototype);
GameBase.prototype.constructor = GameBase;

GameBase.prototype.animateOutcome = function () {
    if (this.outcome === GameBase.OUTCOME_UNKNOWN || cleverapps.focusManager.isFocused()) {
        return;
    }

    if (this.goalCounter && this.goalCounter.isActive()) {
        return;
    }

    this.trigger("animateOutcome", this.outcome);
};

GameBase.prototype.getMode = function () {
    return GameBase.MODE_REGULAR;
};

GameBase.prototype.levelWithTutorial = function () {
    if (cleverapps.config.editorMode) {
        return false;
    }

    if (cleverapps.config.type === "blocks") {
        return BlocksTutorial.isAvailable(this.level);
    }

    if (cleverapps.config.type === "match3") {
        if (this.levelContent.tutorial) {
            if (this.level.isBonusWorldLevel()) {
                return true;
            }

            if (cleverapps.user.isPassedAll() && this.level.isPassedLevel()) {
                return false;
            }

            if (this.level.isCurrentLevel()) {
                return true;
            }
        }
        return false;
    }

    if (cleverapps.config.type === "tile3" || cleverapps.config.type === "solitaire") {
        return TilesTutorial.IsAvailable(this.level);
    }

    if (cleverapps.config.type === "board") {
        return levels.user.episode === 0 && levels.user.level === 0 && this.level.isCurrentLevel() && !cleverapps.isKnockoutGame();
    }

    if (cleverapps.config.type === "merge") {
        return true;
    }
};

GameBase.prototype.displayTutorial = function (f) {
    f();
};

GameBase.prototype.hasBegan = function () {
    if (this.hasDanger()) {
        return true;
    }
    if (["battlefield"].includes(cleverapps.config.type)) {
        return true;
    }
    if (cleverapps.config.type === "match3") {
        return this.beginMoves !== this.moves;
    }
    if (["tile3", "klondike", "solitaire"].includes(cleverapps.config.type) && Boolean(this.move)) {
        return true;
    }

    return this.moves > 0 || this.getPercentOfCompletion() > 0;
};

GameBase.prototype.begin = function (force) {
    if (!this.began && (this.hasBegan() || force)) {
        this.began = true;

        if (this.level.isRegular() || this.level.isBonusWorldLevel()) {
            if (levels.user.isUniqueStart(this.level.episodeNo, this.level.levelNo, this.level.content.version)) {
                this._churnActivated();
            }
        }
    }
};

GameBase.prototype.setBasicReward = function () {
    this.basicReward = 0;
    if (![Meta.HOMEFIX, Meta.SIMPLE, Meta.SHORTMETA, Meta.HOSE, Meta.FARM].includes(cleverapps.meta.getType())) {
        return;
    }
    if (cleverapps.config.type === "battlefield") {
        return;
    }
    if (this.rewards[GameBase.REWARD_EXP]) {
        return;
    }

    if (this.level.isCurrentLevel() || this.level.isDailyCup() || this.level.isDailyLevel() || this.level.isAdsLevel()
        || this.level.isBonusWorldLevel() || this.level.isWeeklyTournamentLevel() || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        this.basicReward = 10;
        if (this.level.isTricky()) {
            this.basicReward = 20;
        }
        if (this.level.isHard()) {
            this.basicReward = 30;
        }
    }
};

GameBase.prototype.addBasicReward = function () {
    var currency = cleverapps.config.soft ? GameBase.REWARD_SOFT : GameBase.REWARD_HARD;

    if (currency === GameBase.REWARD_HARD) {
        this.addHardReward(this.basicReward);
    } else {
        this.addSoftReward(this.basicReward);
    }
};

GameBase.prototype.showMessage = function (message, callback) {
    cleverapps.timeouts.setTimeout(function () {
        this.trigger("showMessage", message);
    }.bind(this), 200);

    var delay = 1.5;
    cleverapps.timeouts.setTimeout(callback, delay * 1000);
};

GameBase.prototype.showStartGameMessage = function (f) {
    if (this.level.isHard()) {
        this.showMessage("message.hardLevel", f);
    } else {
        f();
    }
};

GameBase.prototype._churnActivated = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.UNIQUE_START, {
        hash: this.level.hash
    });
};

GameBase.prototype.setTimeout = function (callback, timeout) {
    return cleverapps.timeouts.setTimeout(function () {
        if (this.stopped) {
            return;
        }

        callback();
    }.bind(this), timeout);
};

GameBase.prototype.getInfo = function () {
    return {
        rewards: this.rewards
    };
};

GameBase.prototype.getInitialInfo = function () {
    return {};
};

GameBase.prototype.storeSave = function () {
    if (this.slot !== undefined) {
        var info = this.getInfo();
        console.log("storeSave");
        console.log(info);

        cleverapps.GameSaver.saveInfo(this.slot, info);
    }
};

GameBase.prototype.eraseSave = function () {
    cleverapps.GameSaver.removeSave(this.slot);
};

GameBase.prototype.loadSave = function () {
    if (this.slot !== undefined) {
        var stored = cleverapps.GameSaver.load(this.slot) || {};

        if (Object.keys(stored).length > 0) {
            return stored;
        }
    }

    return this.getInitialInfo();
};

GameBase.prototype.getDailyCupStars = function () {
    return 1;
};

GameBase.prototype.initPool = function () {

};

GameBase.prototype.stop = function () {
    this.counter.turnOff();
    this.timer.stop();
    levels.FPS.stop();

    runCleaners(this);

    cleverapps.userStatus.destructor();
    cleverapps.menuBar.stopItems();
    cleverapps.exclamation.remove();

    this.stopped = true;
    this.trigger("stop");
};

GameBase.prototype.bonusByLeftMoves = function (f) {
    this.animateBonus(f);
};

GameBase.prototype.needBonusByLeftMoves = function () {
    return false;
};

GameBase.prototype.animateBonus = function (f) {
    f();
};

GameBase.prototype.leave = function (f) {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        f();
        return;
    }

    if (this.stillNoPenalty()) {
        f();
        this.giveUp();
        return;
    }

    if (this.level.isBonus() && this.level.isNew()) {
        f();
        this.win();
        return;
    }

    new ConfirmExitWindow({
        action: this.lose.bind(this)
    });
    cleverapps.focusManager.onceNoWindowsListener = f;
};

GameBase.prototype.takeLife = function (silent) {
    if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
        if (this.level.isDailyCup() && !cleverapps.dailyCup.withLives()) {
            return;
        }
        cleverapps.lives.take(silent);
    }
};

GameBase.prototype.giveLife = function () {
    if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
        if (this.level.isDailyCup() && !cleverapps.dailyCup.withLives()) {
            return;
        }

        if (this.moreThenMaxRegenLife || cleverapps.lives.amount < cleverapps.lives.getMaxLives()) {
            cleverapps.lives.give();
        }
    }
};

GameBase.prototype.setOutcome = function (outcome) {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    this.outcome = outcome;

    cleverapps.user.incProgressCompare(outcome === GameBase.OUTCOME_VICTORY ? 30 : 1);

    if (cleverapps.config.debugMode) {
        console.log("OUTCOME: " + outcome);
    }

    BeforeWinAction.call(this);
    this.trigger("outcome", outcome);
    this.counter.trigger();
};

GameBase.prototype.win = function () {
    this.setOutcome(GameBase.OUTCOME_VICTORY);
};

GameBase.prototype.lose = function () {
    this.setOutcome(GameBase.OUTCOME_LOST);
};

GameBase.prototype.giveUp = function () {
    this.setOutcome(GameBase.OUTCOME_GAVEUP);
};

GameBase.prototype.playIntro = function (f, silent) {
    if (cleverapps.config.debugMode) {
        cleverapps.snapshots.extractData(function (data) {
            this.introData = data;
        }.bind(this));
    }

    var actions = this.listIntroActions();

    if (silent) {
        actions = actions.map(function (action) {
            return function (f) {
                action(f, silent);
            };
        });
    }

    this.introPlaying = true;

    cleverapps.focusManager.compound(function () {
        this.introPlaying = false;
        this.introData = undefined;
        f();
        this.counter.trigger();
    }.bind(this), actions);
};

GameBase.prototype.incCounter = function (f) {
    if (!cleverapps.config.editorMode) {
        this.counter.turnOn();
    }
    this.counter.inc();
    f();
};

GameBase.prototype.decCounter = function (f) {
    this.counter.dec();
    cleverapps.userStatus.reportUserAction();
    f();
};

GameBase.prototype.listIntroActions = function () {
    return [
        this.incCounter.bind(this),
        this.prepareBoosters.bind(this),
        this.showScreen.bind(this),
        this.updateRestoreProgress.bind(this),
        this.beforeGameStart.bind(this),
        this.showStartGameMessage.bind(this),
        this.decCounter.bind(this),
        this.startTutorial.bind(this),
        this.showDailyLevelWindow.bind(this),
        this.executeActionsBefore.bind(this),
        this.runFPS.bind(this)
    ];
};

GameBase.prototype.startTutorial = function (f) {
    if (this.levelWithTutorial()) {
        this.displayTutorial(f);
    } else {
        f();
    }
};

GameBase.prototype.prepareBoosters = function (f) {
    for (var id in cleverapps.boosters.boosters) {
        cleverapps.boosters.boosters[id].onGameStarted();
    }
    f();
};

GameBase.prototype.showScreen = function (f) {
    f();
};

GameBase.prototype.updateRestoreProgress = function (f) {
    cleverapps.restoreProgress.update();
    f();
};

GameBase.prototype.beforeGameStart = function (f) {
    if (this.competition) {
        this.competition.start();
    }
    f();
};

GameBase.prototype.runFPS = function (f) {
    if (this.level.isRegular()) {
        levels.FPS.run(this.level.episodeNo, this.level.levelNo);
    }

    f();
};

GameBase.prototype.showDailyLevelWindow = function (f) {
    if (cleverapps.config.wysiwygMode || !cleverapps.config.features.includes("dailylevel") || DailyLevelWindow.shown || !this.level.isDailyLevel()
        || !["olympics", "crocword", "tripeaks", "spades"].includes(cleverapps.config.name)) {
        f();
        return;
    }

    DailyLevelWindow.shown = true;

    new DailyLevelWindow();
    cleverapps.focusManager.onceNoWindowsListener = f;
};

GameBase.prototype.useLives = function () {
    return cleverapps.lives;
};

GameBase.EXP_PRIZE = 1;
GameBase.HARD_LEVEL_EXP_PRIZE = 3;

GameBase.BONUS_REWARD = 10;
GameBase.DANGER_REWARD = 15;

GameBase.REWARD_SECONDARY = "clover";
GameBase.REWARD_EXP = "exp";
GameBase.REWARD_HARD = "hard";
GameBase.REWARD_BOOSTERS = "boosters";
GameBase.REWARD_SOFT = "soft";
GameBase.REWARD_STICKERS = "stickers";

GameBase.OUTCOME_UNKNOWN = undefined;
GameBase.OUTCOME_VICTORY = 1;
GameBase.OUTCOME_LOST = 2;
GameBase.OUTCOME_GAVEUP = 3;

GameBase.MODE_REGULAR = "regular";
GameBase.MODE_HIGHSCORE = "highscore";

GameBase.prototype.stillNoPenalty = function () {
    if (cleverapps.isRumble()) {
        return false;
    }
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }
    if (this.level.isBonusRound()) {
        return false;
    }
    if (["board", "battlefield"].includes(cleverapps.config.type)) {
        return true;
    }
    if (cleverapps.config.type === "match3") {
        return !this.moveMade;
    }

    return !this.hasBegan();
};

GameBase.prototype.getMissionType = function () {
    if (this.secondaryMission) {
        return this.secondaryMission.type;
    }

    return undefined;
};

GameBase.prototype.addClover = function (missionType, amount, silent) {
    if (this.getMissionType() === missionType) {
        this.rewards[GameBase.REWARD_SECONDARY] = (this.rewards[GameBase.REWARD_SECONDARY] || 0) + amount;
        if (!silent) {
            this.trigger("rewardClover");
        }
    }
};

GameBase.prototype.addHardReward = function (amount, silent) {
    this.rewards[GameBase.REWARD_HARD] = (this.rewards[GameBase.REWARD_HARD] || 0) + amount;
    if (!silent) {
        this.trigger("rewardHard", amount);
    }
};

GameBase.prototype.addSoftReward = function (amount, options) {
    this.rewards[GameBase.REWARD_SOFT] = (this.rewards[GameBase.REWARD_SOFT] || 0) + amount;

    this.trigger("rewardSoft", amount, options);
};

GameBase.prototype.executeActionsBefore = function (f, silent) {
    if (silent) {
        f();
        return;
    }

    cleverapps.focusManager.compound(f, this.actionsBefore.map(function (action) {
        return function (f) {
            var success = GameBase.ActionsBefore[action.type](action);
            if (success && action.source) {
                action.source.execute();
            }

            var duration = action.duration === undefined ? 0.3 : action.duration;
            if (action.getDuration) {
                duration = action.getDuration();
            }
            if (duration) {
                Game.currentGame.counter.setTimeout(f, duration * 1000);
            } else {
                f();
            }
        };
    }));
};

GameBase.prototype.getPercentOfCompletion = function () {
    return 0;
};

GameBase.prototype.onAcceptProlongationOffer = function (offer) {
    if (offer.reward === Prolongation.REWARDS.MOVES) {
        this.setMoves(offer.moves);
    }
};

GameBase.prototype.getProlongationMoves = function () {
    return 5;
};

GameBase.prototype.getNormalizedLevelReward = function (rewardConfig, benchmark) {
    var levelTag = this.level.getTag();
    var rewardsAmount = rewardConfig[levelTag] || rewardConfig.default;
    var avgLevelFinished = GameBase.AVG_LEVELS_FINISHED[cleverapps.config.type];

    if (!avgLevelFinished) {
        return rewardsAmount;
    }

    var multiplier = GameBase.AVG_LEVELS_FINISHED[benchmark] / avgLevelFinished;
    multiplier = Math.min(multiplier, 3);
    rewardsAmount *= multiplier;

    var integerPart = Math.floor(rewardsAmount);
    var fractionalPart = rewardsAmount - integerPart;
    var randomChance = Math.random();
    var finalReward = (fractionalPart > randomChance) ? integerPart + 1 : integerPart;
    return finalReward;
};

GameBase.prototype.hasDanger = function () {
    return this.level.isDanger();
};

GameBase.LEVEL_WITH_COINS_AVAILABLE = {
    level: 0.93
};

GameBase.AVG_LEVELS_FINISHED = {
    board: 5.08,
    match3: 4.95,
    solitaire: 7.32,
    tile3: 3.35,
    blocks: 4.95,
    differences: 8.47
};
