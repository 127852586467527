/**
 * Created by mac on 2019-02-09
 */

cleverapps.Information = function () {
    this.data = cleverapps.dataLoader.load(DataLoader.TYPES.INFORMATION);
    if (this.data === undefined) {
        this.data = {};
    }

    this.diff = {};
};

cleverapps.Information.prototype.onChangeUserId = function () {
    this.diff = this.data;
    this.update();
};

cleverapps.Information.prototype.reportMaxTextureSize = function () {
    if (typeof gl !== "undefined") {
        var maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
        this.setKeyValue("maxTextureSize", maxTextureSize);
    }
};

cleverapps.Information.prototype.reportPhone = function () {
    var sources = {};
    sources[cc.sys.OS_ANDROID] = { avatar: "#avatars/android_avatar.png", name: "Android" };
    sources[cc.sys.OS_IOS] = { avatar: "#avatars/ios_avatar.png", name: "iOS" };

    if (sources[connector.info.os] && connector.info.isNative) {
        var socialHasAvatar = connector.social.isLoggedIn() && !connector.social.oneOf(connector.APPLE);
        if (!this.data.avatar && !socialHasAvatar) {
            cleverapps.info.setKeyValue("avatar", sources[connector.info.os].avatar);
        }
        if (!this.data.name && !connector.social.isLoggedIn()) {
            cleverapps.info.setKeyValue("name", sources[connector.info.os].name + "_" + connector.platform.getUserID().substr(-3));
        }
    }

    if (connector.platform.oneOf(connector.GDCOM, connector.COOLMATHGAMES)) {
        cleverapps.info.setKeyValue("avatar", "#avatars/game_distribution.png");
    }
};

cleverapps.Information.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.INFORMATION, this.data);
};

cleverapps.Information.prototype.getValue = function (key) {
    return this.data[key];
};

cleverapps.Information.prototype.setKeyValue = function (key, value, force) {
    var newValue;

    try {
        newValue = JSON.stringify(value);
    } catch (e) {
        console.log("error stringify - " + key, value);

        if (cleverapps.config.debugMode) {
            throw e;
        } else {
            cleverapps.throwAsync("error stringify - " + key);
            return;
        }
    }

    if (JSON.stringify(this.data[key]) !== newValue || force) {
        this.data[key] = value;
        this.diff[key] = value;
        this.update();
    }
};

cleverapps.Information.prototype.update = function () {
    this.save();
    this._updateInner();
};

cleverapps.Information.prototype._updateInner = cleverapps.accumulate(5000, function () {
    console.log("Sending information: " + JSON.stringify(this.diff));

    cleverapps.RestClient.post("/info/" + encodeURIComponent(connector.platform.getUserID()), this.diff, function () {
        // console.log("Success updating info: " + connector.platform.getUserID() + " = " + JSON.stringify(data));
    }, function () {
        // console.log("Failed updating info: " + connector.platform.getUserID() + " = " + JSON.stringify(data));
    });

    this.diff = {};
});

cleverapps.Information.prototype.getUserAgent = function () {
    var name;
    var version;

    if (connector.platform.oneOf(
        connector.RUSTORE,
        connector.AMAZON,
        connector.ANDROID,
        connector.GPG,
        connector.DIGITAL,
        connector.IOS,
        connector.MACOS,
        connector.WECHAT
    )) {
        name = connector.info.deviceName;
        version = connector.info.deviceVersion;
    } else {
        var uaParser = new UAParser();
        var result = uaParser.getResult(navigator.userAgent);

        name = result.browser.name;
        version = result.browser.major;
    }

    return {
        browser: name,
        version: version
    };
};

cleverapps.Information.prototype.reportUserAgent = function () {
    this.setKeyValue("useragent", this.getUserAgent());
};

cleverapps.Information.prototype.reportSysLanguage = function () {
    this.setKeyValue("syslanguage", connector.info.language);
};

cleverapps.Information.prototype.reportIP = function () {
    this.setKeyValue("ip", "blank", true);
};