/**
 * Created by mac on 12/1/24
 */

var VipAdsAdapter = function () {
    this.saved = {};

    cleverapps.paymentsHistory.onAddPaymentListeners.vip = this.adapt.bind(this);
    cleverapps.subscription.on("update", this.adapt.bind(this));
    cleverapps.flags.on("change:monetization", this.adapt.bind(this));

    this.saved.isRewardedAvailable = RewardedAdsManager.prototype.isRewardedAvailable;
    this.saved.playRewarded = RewardedAdsManager.prototype.playRewarded;

    this.adapt();
};

VipAdsAdapter.prototype.getVIPStatus = function () {
    if (cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
        return {
            status: false
        };
    }

    if (cleverapps.subscription.isActive()) {
        return {
            status: true,
            end: Date.now() + cleverapps.parseInterval("1 day")
        };
    }

    var end = cleverapps.paymentsHistory.getVipEnd(VipAdsAdapter.VIP_THRESHOLD);
    if (end) {
        return {
            status: true,
            end: end
        };
    }

    return {
        status: false
    };
};

VipAdsAdapter.VIP_THRESHOLD = 29.99;

VipAdsAdapter.prototype.calculateMinimalVipSum = function () {
    var product = connector.payments.getProduct("gold6000");

    return connector.payments.getTextPrice(Math.ceil(product.price + 0.01));
};

VipAdsAdapter.prototype.isVIP = function () {
    return this.getVIPStatus().status;
};

VipAdsAdapter.prototype.adapt = function () {
    var status = this.isVIP();

    if (status) {
        RewardedAdsManager.prototype.isRewardedAvailable = this.isRewardedAvailableVIP;
        RewardedAdsManager.prototype.playRewarded = this.playRewardedVIP.bind(this);
    } else {
        RewardedAdsManager.prototype.isRewardedAvailable = this.saved.isRewardedAvailable;
        RewardedAdsManager.prototype.playRewarded = this.saved.playRewarded;
    }
};

VipAdsAdapter.prototype.isRewardedAvailableVIP = function () {
    return connector.ads.isRewardedSupported;
};

VipAdsAdapter.prototype.playRewardedVIP = function (adLimit, onSuccessCallback, onCancelCallback) {
    onSuccessCallback = onSuccessCallback || function () {};
    onCancelCallback = onCancelCallback || function () {};

    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        onSuccessCallback();
        return;
    }

    if (cleverapps.rewardedAdsManager.adIsRunning()) {
        onCancelCallback();

        cleverapps.notification.create("AdsRunning");
        return;
    }

    if (this.isVIP()) {
        this.playVipRewarded(onSuccessCallback, onCancelCallback);
    }
};

VipAdsAdapter.prototype.playVipRewarded = function (onSuccessCallback, onCancelCallback) {
    cleverapps.rewardedAdsManager._isAdRunning = true;

    var reward = false;

    var onReward = function () {
        reward = true;
    };

    cleverapps.focusManager.display({
        stack: true,
        focus: "VIPRewardWindow",
        actions: [
            function (f) {
                new VIPRewardedWindow(onReward);
                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                cleverapps.rewardedAdsManager._isAdRunning = false;

                f();

                if (reward) {
                    cleverapps.eventBus.trigger("taskEvent", DailyTasks.WATCH_ADS);
                    onSuccessCallback();
                } else {
                    onCancelCallback();
                }
            }
        ]
    });
};