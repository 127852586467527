/**
 * Created by andrey on 19.02.18.
 */

var NoAdsIcon = function () {
    SideBarIcon.call(this);
};

NoAdsIcon.prototype = Object.create(SideBarIcon.prototype);
NoAdsIcon.prototype.constructor = NoAdsIcon;

NoAdsIcon.prototype.resetState = function () {
    this.available = cleverapps.noAds && cleverapps.noAds.needToNudge();
    this.setTitle(Product.CreateById("noAds").getCurrentPrice());

    if (this.available) {
        this.setAnimation(bundles.sidebar.jsons.noads_icon_json);
    }
};

NoAdsIcon.prototype.onPressed = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.SIDEBAR_CLICK);

    cleverapps.focusManager.display({
        focus: "NoAdsIcon",
        action: function (f) {
            var product = Product.CreateById("noAds");

            product.buy(function (success) {
                if (success) {
                    product.logBuyItem();

                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.SIDEBAR_PURCHASE);

                    new RewardWindow({ noAds: 1 });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                } else {
                    f();
                }
            }, { noRewardWindow: true });
        }
    });
};
