/**
 * Created by andrey on 19.01.18.
 */

var Cryptex = function (game) {
    this.onAttempt = function () {};
    this.onOpen = function () {};
    this.onFail = function () {};
    this.onStop = function () {};
    
    this.onShowForceListener = function () {};

    this.reward = new Reward("hard", Cryptex.GOLD_REWARD, {
        event: cleverapps.EVENTS.EARN.CRYPTEX
    });
    
    this.running = true;
    this.opened = false;
    this.game = game;

    this.needAttempts = Cryptex.ATTEMPTS;
    if (cleverapps.config.type === "board") {
        this.needAttempts -= Object.keys(game.board.foundWordsSet).length;
    }

    var gameStart = game.timer.getStartTime();
    var duration = gameStart + cleverapps.parseInterval(Cryptex.TIMEOUT) - Date.now();

    this.countdown = new cleverapps.CountDown(duration);
    this.countdown.onFinish = this.timerFinish.bind(this);
};

Cryptex.IsNeeded = function (game) {
    if (cleverapps.flags.cryptexDisabled) {
        return false;
    }

    if (!cleverapps.user.checkAvailable(Cryptex.AVAILABLE)) {
        return false;
    }

    var level = game.level;

    if (level.isBonusRound() || !level.isNew()) {
        return false;
    }

    if (game.totalItemsToSolve() <= Cryptex.ATTEMPTS || Cryptex.ATTEMPTS <= game.countDiscovered()) {
        return false;
    }

    return game.timer.getStartTime() + cleverapps.parseInterval(Cryptex.TIMEOUT) > Date.now();
};

Cryptex.prototype.timerFinish = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CRYPTEX_FAIL);
    this.onFail();
    this.stop();
};

Cryptex.prototype.isOpen = function () {
    return this.opened;
};

Cryptex.prototype.attempt = function () {
    if (this.running) {
        this.needAttempts--;

        if (this.needAttempts === 0) {
            this.game.counter.inc();

            var callback = function () {
                this.game.counter.dec();
            }.bind(this);

            this.opened = true;
            this.stop();

            this.open(callback);
        } else {
            this.onAttempt();
        }
    }
};

Cryptex.prototype.open = function (callback) {
    this.onStop();

    this.onOpen(function () {
        this.opened = false;
        callback();
    }.bind(this));
};

Cryptex.prototype.getReward = function () {
    return this.reward;
};

Cryptex.prototype.canShowForce = function () {
    return !cleverapps.forces.isShown(Forces.CRYPTEX.id) && this.running && !cleverapps.forces.isRunningForce();
};

Cryptex.prototype.showForce = function () {
    this.onShowForceListener();
};

Cryptex.prototype.hideForce = function () {
    if (cleverapps.forces.isRunningForce(Forces.CRYPTEX)) {
        cleverapps.forces.closeRunningForce();
    }
};

Cryptex.prototype.stop = function () {
    if (this.running) {
        this.hideForce();

        if (this.countdown) {
            this.countdown.stop();
        }
        this.running = false;

        if (!this.isOpen()) {
            this.onStop();
        }
    }
};

Cryptex.ATTEMPTS = 4;
Cryptex.GOLD_REWARD = 30;
Cryptex.TIMEOUT = "4 minute";

if (cleverapps.config.debugMode) {
    Cryptex.ATTEMPTS = 4;
    Cryptex.TIMEOUT = "2 minute";
}

Cryptex.AVAILABLE = {
    level: 6.13
};