/**
 * Created by Aleksandr on 06.04.2023
 */

var InterstitialAction = function (f) {
    if (this.game.outcome === GameBase.OUTCOME_GAVEUP && !cleverapps.config.lives) {
        f();
        return;
    }
    cleverapps.rewardedAdsManager.showInterstitial(function (shown) {
        if (shown && cleverapps.noAds.needToNudge(true)) {
            new NoAdsWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        } else {
            f();
        }
    });
};

InterstitialAction.checkLastFocus = function () {
    var focus = cleverapps.focusManager.lastFocus;
    if (!focus) {
        return false;
    }

    return focus.startsWith("missionCompleted")
        || (InterstitialAction.ALLOWED_FOCUSES[cleverapps.config.type] || []).indexOf(focus) !== -1;
};

InterstitialAction.showOnLowMonetization = function () {
    if (!cleverapps.flags.lowMonetization) {
        return;
    }

    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    cleverapps.rewardedAdsManager.showInterstitial();
};

InterstitialAction.ALLOWED_FOCUSES = {
    merge: ["onLevelUp", "FinishQuest", "ExitExpeditionIconClicked",
        "openChestWindow", "updateBuildPassUnit", "GiveRuinReward",
        "ConfirmSpeedUpWindowLock", "StartUnitDialogue", "StartUnitGreeting"]
};