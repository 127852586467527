/**
 * Created by Andrey Popov on 9/9/22.
 */

var SnapshotBarView = function () {
    cleverapps.snapshotBar.onStartRecording = this.onStartRecording.bind(this);
    cleverapps.snapshotBar.onStopRecording = this.onStopRecording.bind(this);

    cleverapps.snapshotBar.onShowLabel = this.showLabel.bind(this);
    cleverapps.snapshotBar.onHideLabel = this.hideLabel.bind(this);
    cleverapps.snapshotBar.onShowButtons = this.showButtons.bind(this);
    cleverapps.snapshotBar.onHideButtons = this.hideButtons.bind(this);

    this.createContent();
};

SnapshotBarView.prototype.createContent = function () {
    var styles = document.createElement("style");
    styles.innerHTML = ""
        + "#snapshotbar {"
        + "    position: absolute;"
        + "    bottom: 0;"
        + "    right: 0;"
        + "    display: flex;"
        + "    flex-direction: row;"
        + "    justify-content: center;"
        + "    align-items: center;"
        + "    gap: 10px;"
        + "    padding: 10px;"
        + "    z-index: 2000;"
        + "}"
        + "#snapshotbar .snapshotbar-label {"
        + "    position: relative;"
        + "    height: 40px;"
        + "    display: flex;"
        + "    align-items: center;"
        + "    justify-content: center;"
        + "    font-size: 28px;"
        + "    font-weight: bold;"
        + "    color: #000;"
        + "}"
        + "#snapshotbar .snapshotbar-label img {"
        + "    max-width: 60%;"
        + "    max-height: 60%;"
        + "}"
        + "#snapshotbar .snapshotbar-button {"
        + "    position: relative;"
        + "    width: 40px;"
        + "    height: 40px;"
        + "    background-color: #04AA6D;"
        + "    border-radius: 10px;"
        + "    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);"
        + "    cursor: pointer;"
        + "    display: flex;"
        + "    align-items: center;"
        + "    justify-content: center;"
        + "}"
        + "#snapshotbar .snapshotbar-button:hover {"
        + "    background-color: #039a63;"
        + "    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);"
        + "}"
        + "#snapshotbar .snapshotbar-button img {"
        + "    max-width: 60%;"
        + "    max-height: 60%;"
        + "}"
        + "#snapshotbar .snapshotbar-button[data-tooltip]::after {"
        + "    pointer-events: none;"
        + "    position: absolute;"
        + "    top: -5px;"
        + "    left: 50%;"
        + "    transform: translate(-50%, -100%);"
        + "    display: none;"
        + "    content: attr(data-tooltip);"
        + "    font-size: 12px;"
        + "    color: #fff;"
        + "    white-space: nowrap;"
        + "    background-color: #333;"
        + "    border-radius: 3px;"
        + "    padding: 5px;"
        + "    z-index: 10;"
        + "}"
        + "#snapshotbar .snapshotbar-button[data-tooltip]:hover::after {"
        + "    display: block;"
        + "}";
    document.head.appendChild(styles);

    this.bar = document.createElement("div");
    this.bar.id = "snapshotbar";
    document.body.appendChild(this.bar);

    this.label = this.createLabel(cleverapps.snapshotBar.label);
    this.bar.appendChild(this.label);

    this.makeSnapshotButton = this.createButton(bundles.dev_snapshots.urls.snapshot, "Make Snapshot\n(Alt + S)", function () {
        cleverapps.snapshotBar.photo();
    });
    this.bar.appendChild(this.makeSnapshotButton);

    this.captureActionsButton = this.createButton(bundles.dev_snapshots.urls.snapshot_rec, "Record Actions\n(Alt + R)", function () {
        cleverapps.snapshotBar.toggleRecord();
    });
    this.bar.appendChild(this.captureActionsButton);

    this.stopCaptureActionsButton = this.createButton(bundles.dev_snapshots.urls.snapshot_stop, "Stop Record\n(Alt + R)", function () {
        cleverapps.snapshotBar.toggleRecord();
    });
    this.bar.appendChild(this.stopCaptureActionsButton);

    if (cleverapps.config.importMode) {
        this.createSceneButton = this.createButton(bundles.dev_snapshots.urls.snapshots_create_clip, "Create clip", function () {
            cleverapps.snapshotBar.createScene();
        });
        this.bar.appendChild(this.createSceneButton);
    }

    this.hideLabel();
    this.onStopRecording();
};

SnapshotBarView.prototype.createLabel = function (text) {
    var label = document.createElement("div");
    label.className = "snapshotbar-label";
    label.innerHTML = this.createLabelHTML(text);
    return label;
};

SnapshotBarView.prototype.createLabelHTML = function (text) {
    text = text || "";

    var parts = TextWithIcon.splitString(text, Object.keys(SnapshotBarView.ICONS));
    var innerHTML = "";

    parts.forEach(function (part) {
        var icon = SnapshotBarView.ICONS[part];
        if (icon) {
            icon = icon.resolve();
            innerHTML += "<img src='" + icon.url + "'>";
        } else {
            innerHTML += part;
        }
    });

    return innerHTML;
};

SnapshotBarView.prototype.createButton = function (icon, tooltip, callback) {
    icon = icon.resolve();

    var button = document.createElement("div");
    button.className = "snapshotbar-button";
    button.innerHTML = "<img src='" + icon.url + "'>";

    if (tooltip) {
        button.setAttribute("data-tooltip", tooltip);
    }

    if (callback) {
        button.onclick = callback;
    }

    return button;
};

SnapshotBarView.prototype.showButtons = function () {
    this.bar.style.display = "flex";
};

SnapshotBarView.prototype.hideButtons = function () {
    this.bar.style.display = "none";
};

SnapshotBarView.prototype.showLabel = function () {
    this.label.innerHTML = this.createLabelHTML(cleverapps.snapshotBar.label);
    this.label.style.display = "";
};

SnapshotBarView.prototype.hideLabel = function () {
    this.label.style.display = "none";
};

SnapshotBarView.prototype.onStopRecording = function () {
    this.makeSnapshotButton.style.display = "";
    this.stopCaptureActionsButton.style.display = "none";
    this.captureActionsButton.style.display = "";
};

SnapshotBarView.prototype.onStartRecording = function () {
    this.makeSnapshotButton.style.display = "none";
    this.stopCaptureActionsButton.style.display = "";
    this.captureActionsButton.style.display = "none";
};

SnapshotBarView.ICONS = {
    "!!": bundles.dev_snapshots.urls.label_rec,
    "**": bundles.dev_snapshots.urls.label_playback
};