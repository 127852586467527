/**
 * Created by mac on 4/20/20
 */

var LevelManager = function () {

};

LevelManager.prototype.updateLoadedLevel = function () {
    var level = cleverapps.administrator.adminLevels.levels[this.level.levelNo];
    this.levelVersions = level.getLevelVersions();
};

LevelManager.prototype.setLevel = function (options) {
    if (this.level) {
        this.level.destructor();
    }

    cleverapps.bundleLoader.clearUnusedResources();

    this.level = new Level(options.episodeNo, options.levelNo, options);
    this.level.load(this.updateLoadedLevel.bind(this));

    this.setVersion(undefined);
};

LevelManager.getInstance = function () {
    if (!LevelManager.currentManager) {
        LevelManager.currentManager = new LevelManager();
    }

    return LevelManager.currentManager;
};

LevelManager.prototype.playLevel = function (f) {
    if (cleverapps.config.adminMode) {
        cleverapps.setUrlHash({ playLevel: true });
    }

    if (cleverapps.knockoutGame) {
        if (!cleverapps.knockoutGame.isRumble()) {
            cleverapps.knockoutGame.rumble = new Rumble();
        }
        cleverapps.knockoutGame.rumble.whenInitialize(function (success) {
            if (success) {
                this.level.play(f);
            } else {
                cleverapps.knockoutGame.onRumbleInitFailed();
            }
        }.bind(this));

        return;
    }

    if (cleverapps.config.type === "merge") {
        cleverapps.config.adminMode = false;
        cleverapps.flags.norest = true;
        cleverapps.setRestDummies(true);
    }

    var page = cleverapps.travelBook.getPageById(this.level.meta.expedition) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);

    this.level.play(f);
};

LevelManager.prototype.editLevel = function (f) {
    this.level.edit(f);
};

LevelManager.prototype.setVersion = function (version) {
    this.version = version;
};

LevelManager.prototype.getMaxVersion = function () {
    var level = cleverapps.administrator.adminLevels.levels[this.level.levelNo];
    return level.getMaxVersion(level.getLevelVersions());
};

LevelManager.prototype.getMaxVersionContent = function () {
    return this.levelVersions[this.getMaxVersion()];
};

LevelManager.prototype.prepareEdit = function () {
    if (this.version === undefined) {
        this.showAlertMessage("Unknown version.");
        return false;
    }

    if (this.version < this.getMaxVersion()) {
        this.showAlertMessage("You must edit last version.");
        return false;
    }

    if (this.version === this.level.playedProductionMaxVersion) {
        if (confirm("Create new version for edit.")) {
            this.createNewVersion();
            this.editLevel();
        }
        return false;
    }

    return true;
};

LevelManager.prototype.showAlertMessage = cleverapps.throttle(100, function (message) {
    alert(message);
});

LevelManager.prototype.createNewVersion = function () {
    var maxVersion = this.getMaxVersion();

    if (maxVersion > this.level.playedProductionMaxVersion) {
        this.showAlertMessage("Level hasn't played on production yet. You can't increase version!");
        return;
    }

    var data = cleverapps.clone(this.levelVersions[maxVersion], true);
    data.creationTime = new Date().toString();
    this.setVersion(maxVersion + 1);
    this.update(data);

    this.save(function () {
        cleverapps.administrator.log.createEntry({
            type: AdminLog.CREATE_LEVEL_VERSION,
            episodeNo: data.episodeNo,
            levelNo: data.levelNo,
            version: data.version
        });
    });
};

LevelManager.prototype.update = function (data) {
    data.episodeNo = cleverapps.castType(this.level.episodeNo);
    data.levelNo = this.level.levelNo;
    data.version = this.version || this.getMaxVersion();

    this.levelVersions[data.version] = data;

    var levels = Object.values(this.levelVersions);
    var adminEpisode = cleverapps.administrator.adminEpisodes.getEpisode(data.episodeNo);
    adminEpisode.getEpisodeLevels()[data.levelNo] = levels.length > 1 ? levels : levels[0];
};

LevelManager.prototype.save = function (onSuccess) {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getEpisode(this.level.episodeNo);
    cleverapps.administrator.adminEpisodes.saveToStorage(this.level.episodeNo, adminEpisode.getEpisodeLevels());

    if (onSuccess) {
        onSuccess();
    }
};

LevelManager.prototype.processLevelContent = function (processor) {
    var content = this.level.content;

    processor(content);

    this.update(content);
    this.save();
};
