/**
 * Created by razial on 18.12.2023
 */

GitManager.STATUS = "status";
GitManager.PUSH = "push";
GitManager.REVERT = "revert";

GitManager.STATE_READY = 0;
GitManager.STATE_BEHIND = 1;
GitManager.STATE_AHEAD = 2;
GitManager.STATE_DETACHED = 3;

GitManager.STATUS_EMPTY = "empty";
GitManager.STATUS_UNAVAILABLE = "unavailable";
GitManager.STATUS_PROCESSING = "processing";
GitManager.STATUS_ERROR = "error";
GitManager.STATUS_DETACHED = "detached";
GitManager.STATUS_REDY = "ready";

GitManager.FOLDERS = {};

GitManager.FOLDERS.LEVELS = "cleverapps/res/" + cleverapps.config.name + "/levels/";

if (cleverapps.config.type === "match3") {
    GitManager.FOLDERS.LEVELS = "match3/res/levels/";
}

if (cleverapps.config.type === "board") {
    GitManager.FOLDERS.LEVELS = "cleverapps/res/" + cleverapps.config.name + "/levels/en/";
}

if (cleverapps.config.type === "merge") {
    GitManager.FOLDERS.LEVELS = "cleverapps/res/" + cleverapps.config.name + "/locations/";
}

if (cleverapps.config.name === "wordsoup") {
    GitManager.FOLDERS.LEVELS = "res/locations/";
}

if (cleverapps.config.type === "battlefield") {
    GitManager.FOLDERS.LEVELS = "res/" + cleverapps.config.name + "/levels/";
}

if (cleverapps.config.type === "blocks") {
    GitManager.FOLDERS.LEVELS = "res/levels/";
}

GitManager.FOLDERS.VILLAGES = "res/levels/villages.json";

GitManager.FOLDERS.UNIT_ANCHORS = "**/units/*/anchors.json";

GitManager.FOLDERS.WYSIWYG_SCENARIOS = "cleverapps/res/" + cleverapps.config.name + "/wysiwyg/";

GitManager.match = function (path, patterns) {
    path = path.split("/");

    return cleverapps.toArray(patterns).some(function (pattern) {
        pattern = pattern.replace(/\/$/, "/**").split("/");

        var match = function (i, j) {
            for (; i < path.length || j < pattern.length;) {
                if (path[i] === "") {
                    i += 1;
                    continue;
                }

                if (pattern[j] === "") {
                    j += 1;
                    continue;
                }

                if (pattern[j] === "**") {
                    if (i >= path.length) {
                        j += 1;
                        continue;
                    }

                    if (match(i, j + 1)) {
                        return true;
                    }

                    i += 1;
                    continue;
                }

                if (pattern[j] === "*" || pattern[j] === path[i]) {
                    i += 1;
                    j += 1;
                    continue;
                }

                return false;
            }

            return true;
        };

        return match(0, 0);
    });
};

if (typeof cc === "undefined") {
    module.exports = GitManager;
}
