/**
 * Created by andrey on 28.03.2024.
 */

var FirebaseLogger = function () {
    this.batch = [];

    this.sendBatch = cleverapps.accumulate(1000, this._sendBatch.bind(this));

    this.onUpdateUserId();
};

FirebaseLogger.prototype.logEvent = function (name, params) {
    if (!FirebaseLogger.EVENTS[name]) {
        return;
    }

    if (name.length > 40) {
        cleverapps.throwAsync("Invalid event name: " + name
            + "!. It must be between 1 and 40 characters, and must be contain only alphanumerics, , - or spaces, starting with alphanumeric or _.");
    }

    this.send({
        method: "logEvent",
        name: name,
        data: params
    });
};

FirebaseLogger.prototype.logPurchase = function (product, purchase, params) {
    this.send({
        method: "logEvent",
        name: "purchase",
        data: {
            currency: params.currency,
            value: params.price,
            transaction_id: params.transactionId
        }
    });
};

FirebaseLogger.prototype.logAdRevenue = function (name, impressionCost, params) {
    var source = params.source;
    switch (source) {
        case "ad_mob":
            return;
        case "app_lovin":
            source = "appLovin";
            break;
        case "yandex_app_ads":
            source = "yandex";
            break;
    }

    this.send({
        method: "logEvent",
        name: "ad_impression",
        data: {
            ad_platform: source,
            ad_format: name,
            value: impressionCost,
            currency: "USD"
        }
    });
};

FirebaseLogger.prototype.onUpdateUserId = function () {
    this.send({
        method: "setUserID",
        id: connector.platform.getUserID()
    });
};

FirebaseLogger.prototype.onUpdateScreen = function (screen, scene) {
    this.send({
        method: "setScreenName",
        screen: screen,
        scene: scene 
    });
};

FirebaseLogger.prototype.send = function (params) {
    this.batch.push(params);
    this.sendBatch();
};

FirebaseLogger.prototype._sendBatch = function () {
    if (this.batch.length > 0) {
        connector.platform.callNative("FirebasePlugin.sendBatch", { batch: this.batch });
        this.batch = [];
    }
};

FirebaseLogger.isApplicable = function () {
    return connector.platform.oneOf(connector.AMAZON, connector.ANDROID, connector.IOS);
};

FirebaseLogger.EVENTS = ConversionManager.EVENTS;
