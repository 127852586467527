/**
 * Created by Ivan on 19.04.2024
 */

var StickerView = cc.Node.extend({
    ctor: function (sticker, options) {
        this._super();

        this.options = options || {};
        this.sticker = sticker;
        this.bundle = bundles["collection_" + sticker.collection.index];

        this.setAnchorPoint(0.5, 0.5);

        this.createImage();
        this.createLabel();
        this.createFrame();

        this.setCascadeOpacityEnabledRecursively(true);

        this.update();

        if (this.options.withUpdate) {
            sticker.on("update", this.createListener(this.update.bind(this)));
        }
    },

    createFrame: function (rare) {
        var styles = cleverapps.styles.StickerView.frame;
        var animation;
        switch (rare) {
            case false:
                animation = bundles.sticker_collection_extra.jsons.silver_frame_json || this.bundle.jsons.silver_frame_json;
                break;
            case true:
                animation = bundles.sticker_collection_extra.jsons.gold_frame_json || this.bundle.jsons.gold_frame_json;
                break;
            default:
                animation = bundles.sticker_collection_extra.jsons.gray_frame_json || this.bundle.jsons.gray_frame_json;
                break;
        }
        if (this.frame) {
            this.frame.removeFromParent();
            this.frame = undefined;
        }
        var frame = this.frame = new cleverapps.Spine(animation);
        frame.setAnimation(0, "animation");
        this.setContentSize(frame.getContentSize());
        frame.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(frame);
        frame.setLocalZOrder(styles.zOrder);
    },

    createImage: function () {
        var styles = cleverapps.styles.StickerView.image;
        var image = this.image = new cc.Sprite();
        image.setPositionRound(styles);
        styles.zOrder && image.setLocalZOrder(styles.zOrder);

        this.addChild(image);
        image.setLocalZOrder(0);
    },

    update: function () {
        var styles = cleverapps.styles.StickerView.label;
        var opened = this.sticker.getState() === Sticker.STATE_OPENED;

        if (opened) {
            this.image.setSpriteFrame(this.sticker.image);
            this.image.setVisible(true);
            this.createFrame(this.sticker.isRare());
            this.text.setVisible(true);
        } else {
            this.image.setSpriteFrame(this.bundle.frames.locked_image_png);
            this.createFrame();

            if (cleverapps.styles.StickerView.hideLocked) {
                this.text.setVisible(false);
                this.image.setVisible(false);
            }
        }

        this.text.setPositionRound(opened ? styles.openedPosition : styles.lockedPosition);

        if (this.options.badge && this.sticker.hasAttention()) {
            this.createBadge();
        } else {
            this.removeBadge();
        }
    },

    createLabel: function () {
        var styles = cleverapps.styles.StickerView.label;

        var text = this.text = cleverapps.UI.generateOnlyText(this.sticker.title, cleverapps.styles.FONTS.COLLECTION_WINDOW_TEXT);
        text.setPositionRound(styles.lockedPosition);
        text.fitTo(styles.width);
        text.setLocalZOrder(12);

        this.addChild(text);
    },

    createBadge: function () {
        if (this.badge) {
            return;
        }

        var styles = cleverapps.styles.StickerView.badge;

        var badge = this.badge = new cc.Sprite(this.bundle.frames.badge_png);
        badge.setLocalZOrder(11);

        var text = cleverapps.UI.generateOnlyText("Attention.Text", cleverapps.styles.FONTS.COLLECTION_BADGE_TEXT);
        badge.addChild(text);
        text.setPositionRound(styles.text);
        text.fitTo(styles.text.maxWidth);
        text.setRotation(styles.text.rotation);

        this.addChild(badge);
        badge.setPositionRound(styles);
    },

    removeBadge: function () {
        if (!this.badge) {
            return;
        }

        this.badge.removeFromParent();
    }
});

cleverapps.styles.StickerView = {
    image: {
        x: { align: "center" },
        y: { align: "center", dy: 35 }
    },

    label: {
        openedPosition: {
            x: { align: "center" },
            y: { align: "top", dy: -265 }
        },
        lockedPosition: {
            x: { align: "center" },
            y: { align: "top", dy: -265 }
        },
        width: 170
    },

    badge: {
        x: { align: "right", dx: 30 },
        y: { align: "top", dy: 20 },
        text: {
            x: { align: "center" },
            y: { align: "center" },
            maxWidth: 70,
            rotation: 20
        }
    },

    points: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -50 },
        margin: -7
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 98 }
    },

    effect: {
        x: { align: "center" },
        y: { align: "center" }
    },

    tape: false,

    frame: {
        zOrder: 1
    }
};