/**
 * Created by andrey on 23.04.2024
 */

var Transition = cc.Node.extend({
    ctor: function (fromScene, toScene) {
        this._super();

        this.fromScene = fromScene;
        this.toScene = toScene;

        this.setLocalZOrder(Transition.ZORDER);

        this.setVisible(false);

        fromScene.addChild(this);

        this.type = this.getType();

        this.setupChildren();
        this.createContent();
        this.updateSize();
    },

    createContent: function () {
        if (this.type === Transition.TYPE_KEEP_BG) {
            this.createKeepBackground();
        } else if ([Transition.TYPE_DEFAULT, Transition.TYPE_VICTORY].includes(this.type)) {
            this.background = new TransitionBackground(this.type);
            this.addChild(this.background);

            this.content = new TransitionContent(this.type);
            this.addChild(this.content);

            if (TransitionLoading.isAvailable() && this.type === Transition.TYPE_DEFAULT) {
                this.loading = new TransitionLoading();
                this.addChild(this.loading);
            }
        }
    },

    getType: function () {
        var type = Transition.TYPE_DEFAULT;

        if (cleverapps.flags.noTransitionAnimation) {
            type = Transition.TYPE_EMPTY;
        } else if (this.fromScene.transitionVictory) {
            type = Transition.TYPE_VICTORY;
        } else if (this.canTransitionKeepBg()) {
            type = Transition.TYPE_KEEP_BG;
        } else if (this.toScene.transitionType) {
            type = this.toScene.transitionType;
        }
        return type;
    },

    setupChildren: function () {
        var safePadding = cleverapps.resolution.getSafePadding();
        this.setPosition(-safePadding.left, -safePadding.bottom);
    },

    updateSize: function () {
        var bgSize = cleverapps.resolution.getBgSize();

        this.setContentSize(bgSize);

        if (this.background) {
            var styles = cleverapps.styles.Transition[this.type];
            var scale = styles.scale * Math.max(bgSize.height / this.background.height, bgSize.width / this.background.width);
            this.background.setScale(Math.max(scale, 1));
        }
    },

    createKeepBackground: function () {
        var background = this.fromScene.background;
        this.fromScene.background = undefined;

        background.replaceParentSamePlace(this, {
            keepScale: true
        });

        if (!cleverapps.oneOf(background, LoaderSceneBackground)) {
            delete background.alignment;
        }

        this.bundles = background.transitionBundles;
    },

    canTransitionKeepBg: function () {
        var background = this.fromScene.background;
        if (!background || !background.transitionBundles) {
            return false;
        }

        if (cc.game.isPaused()) {
            return true;
        }

        if (this.toScene instanceof GameSceneBase && cleverapps.config.type === "merge") {
            return false;
        }

        if (!this.toScene.bundles) {
            return false;
        }

        var externalBundles = this.toScene.bundles.filter(function (name) {
            return bundles[name].meta && bundles[name].meta.external;
        });

        return cleverapps.bundleLoader.isLoaded(this.toScene.bundles)
            || connector.info.isNative && externalBundles.league === 0;
    },

    show: function (f, silent) {
        this.setVisible(true);

        cleverapps.focusManager.compound(f, [
            function (f) {
                this.content && this.content.show(silent);

                if (this.background) {
                    this.background.show(f, silent);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (this.loading) {
                    this.loading.show(silent);
                }
                f();
            }.bind(this)
        ]);
    },

    hide: function (f, silent) {
        new ActionPlayer([
            function (f) {
                this.loading && this.loading.hide();
                this.content && this.content.hide(silent);

                if (this.background) {
                    this.background.hide(f, silent);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                this.setVisible(false);
                f();
            }.bind(this)
        ]).play(f);
    }
});

Transition.isVictoryTransitionAvailable = function () {
    return bundles.transition.jsons.transition_victory_curtains_json;
};

Transition.ZORDER = 1000;

Transition.TYPE_DEFAULT = "default";
Transition.TYPE_VICTORY = "victory";
Transition.TYPE_KEEP_BG = "keep_bg";
Transition.TYPE_EMPTY = "empty";

cleverapps.styles.Transition = {

};

cleverapps.styles.Transition[Transition.TYPE_DEFAULT] = {
    scale: 1.05
};

cleverapps.styles.Transition[Transition.TYPE_VICTORY] = {
    scale: 1.2
};
