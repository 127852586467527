/**
 * Created by vladislav on 1/18/2024
 */

var CleverAppsLogic = function () {
    PlatformLogic.call(this);
};

CleverAppsLogic.prototype = Object.create(PlatformLogic.prototype);
CleverAppsLogic.prototype.constructor = CleverAppsLogic;

CleverAppsLogic.prototype.loadOldUserId = function (callback) {
    try {
        var oldUser = cleverapps.dataLoader.localStorage.getItem(cleverapps.config.name + "_xsolla");
        callback(oldUser && JSON.parse(oldUser).id);
    } catch (e) {
        console.log("parse old user error - " + oldUser, e);
    }
};

CleverAppsLogic.prototype.logPushes = function () {
    var params = connector.utils.getRequestParameters();

    var pushCode = params.push_code;
    if (pushCode) {
        cleverapps.localPushes.logClickEvent(pushCode);
    }
};