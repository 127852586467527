/**
 * Created by Ivan on 17.10.2024
 */

var OpenLetterBooster = function (id) {
    Booster.call(this, id);
    this.state = OpenLetterBooster.INACTIVE;
};

OpenLetterBooster.prototype = Object.create(Booster.prototype);
OpenLetterBooster.prototype.constructor = OpenLetterBooster;

OpenLetterBooster.prototype.onGameStarted = function () {
    if (this.isForceAvailable()) {
        cleverapps.userStatus.on("inactive_short", this.showForce.bind(this));
    }
};

OpenLetterBooster.prototype.onExecute = function () {
    this.state = OpenLetterBooster.ACTIVE;

    Game.currentGame.keypad.chooseBoosterLetter();
};

OpenLetterBooster.prototype.isActive = function () {
    return this.state === OpenLetterBooster.ACTIVE;
};

OpenLetterBooster.prototype.isAnimating = function () {
    return this.state === OpenLetterBooster.ANIMATION;
};

OpenLetterBooster.prototype.open = function (letter) {
    var hintAnimationDuration = 0.3;
    var hintAnimationTimeout = hintAnimationDuration + 0.5;

    var letters = Game.currentGame.board.selectLettersPositions(letter.symbol);
    var maxDelay = 0;

    letters.forEach(function (pos) {
        var delay = pos[1] * 100 + 750;

        cleverapps.timeouts.setTimeout(function () {
            Game.currentGame.board.addHint(pos[0], pos[1], cleverapps.Board.SPAWN_LETTER_ANIMATION);
        }, delay);

        maxDelay = Math.max(delay, maxDelay);
    });

    this.state = OpenLetterBooster.ANIMATION;

    if (!this.forceActive) {
        if (!this.canExecute()) {
            this._buy();
        }
        this.use();
    }
    this.forceActive = undefined;

    Game.currentGame.counter.setTimeout(function () {
        this.afterExecute();
    }.bind(this), maxDelay + hintAnimationTimeout * 1000);

    this.trigger("afterExecute");
};

OpenLetterBooster.prototype.execute = function (isRunningForce) {
    if (isRunningForce) {
        this.forceActive = true;
        this.use();
    }

    this.onExecute();
    this.onExecuteListener();
};

OpenLetterBooster.prototype.afterExecute = function () {
    Game.currentGame.keypad.openLetterChoosed();
    this.state = OpenLetterBooster.INACTIVE;
};

OpenLetterBooster.prototype._buy = BaseBooster.prototype.buy;
OpenLetterBooster.prototype.buy = function () {
    var price = this.getPrice();
    return cleverapps.user.canTakeHard(price.amount);
};

OpenLetterBooster.INACTIVE = 0;
OpenLetterBooster.ACTIVE = 1;
OpenLetterBooster.ANIMATION = 2;