/**
 * Created by mac on 9/25/20
 */

var AdministratorScene = cleverapps.FixedWidthScene.extend({
    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_ADMINISTRATOR);

        this.mode = AdministratorScene.MODES.HORIZONTAL;

        this.nodes = {};

        this.nodes.adminEpisodes = new AdminEpisodesView(cleverapps.administrator.adminEpisodes);
        this.nodes.adminLevels = new AdminLevelsView(cleverapps.administrator.adminLevels);

        var margin = cleverapps.styles.AdministratorScene.margin;

        var winSize = cleverapps.resolution.getSceneSize();
        var width = winSize.width - this.nodes.adminEpisodes.width - this.nodes.adminLevels.width;
        this.nodes.adminDetails = new AdminDetailsView(cleverapps.administrator.adminDetails, {
            width: width - margin * 2
        });

        this.createGlobalButtons();

        var layout = new cleverapps.Layout(Object.values(this.nodes), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: margin
        });

        layout.setAnchorPoint(0, 1);
        layout.setPositionRound(0, this.height);
        this.addChild(layout);

        this.calcMode();

        cleverapps.git.changeFolders(GitManager.FOLDERS.LEVELS);
    },

    _closeAction: function () {
        cleverapps.config.adminMode = false;
        cleverapps.setUrlHash({
            episode: undefined,
            level: undefined,
            mode: undefined
        });
        cleverapps.FixedWidthScene.prototype._closeAction.call(this);
    },

    calcMode: function () {
        var calculatedMode = AdministratorScene.MODES.HORIZONTAL;

        if (this.mode !== calculatedMode) {
            this.mode = calculatedMode;

            Object.values(this.nodes).forEach(function (node) {
                node.switchMode(this.mode);
            }, this);
        }
    },

    getBackgroundStyles: function () {
        return {
            scale9: bundles.admin.frames.background
        };
    },

    createGlobalButtons: function () {
        this.nodes.adminDetails.addChild(new GitButtonsView({
            direction: cleverapps.UI.VERTICAL
        }), 1);

        this.createProductsCacheButton();
    },

    createProductsCacheButton: function () {
        if (typeof FB === "undefined" || !FB.api) {
            return;
        }

        var button = new AdminFbProductsButton(new AdminFBProducts());
        button.setPositionRound(cleverapps.styles.AdminFbProductsButton);
        this.nodes.adminDetails.addChild(button);
    },

    listBundles: function () {
        var result = ["admin", "game", "tiles", "units_main"];

        result = result.concat(AdminLevelMiniature.bundles.match3()).concat(AdminLevelMaxiature.bundles.match3());

        return cleverapps.unique(result);
    }
});

cleverapps.styles.AdministratorScene = {
    margin: 50
};

AdministratorScene.open = function (f) {
    var actions = [
        AdminEpisodes.ResetAction,

        function (f) {
            var active = cleverapps.administrator.adminLevels.getActive();

            cleverapps.setUrlHash({
                episode: active ? active.episodeNo : undefined,
                level: active ? active.levelNo : undefined,
                mode: "admin"
            });

            f();
        },

        function (f) {
            cleverapps.config.adminMode = true;
            connector.ads.closeStickyCallback();
            cleverapps.scenes.replaceScene(new AdministratorScene(), f);
        }
    ];

    if (f) {
        cleverapps.focusManager.compound(f, actions);
    } else if (cleverapps.focusManager.isFocused()) {
        cleverapps.focusManager.distract({
            focus: "administrator",
            actions: actions
        });
    } else {
        cleverapps.focusManager.display({
            focus: "administrator",
            actions: actions
        });
    }
};

AdministratorScene.IsAvailable = function () {
    if (cleverapps.isLocalhost()) {
        return true;
    }
    return cleverapps.dataLoader.localStorage.getItem("adminAccess") === "-1454397529"
        && AdministratorScene.WHITELIST.indexOf(cleverapps.dataLoader.localStorage.getItem("adminAccess_username")) !== -1;
};

(function () {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!params.mode || !cleverapps.config.debugMode) {
        return;
    }

    var baseOnStartup = cleverapps.Plot.onStartup;
    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        if (typeof AdministratorScene !== "undefined" && !AdministratorScene.IsAvailable()) {
            return baseOnStartup.call(cleverapps.Plot, f, returnClassOnly);
        }

        cleverapps.config.adminMode = true;

        if (params.mode === "editor") {
            cleverapps.config.editorMode = true;
        }

        if (params.playLevel) {
            if (returnClassOnly) {
                return cleverapps.meta.getMainScene();
            }

            cleverapps.focusManager.compound(f, [
                AdminEpisodes.ResetAction,

                function (f) {
                    LevelManager.getInstance().playLevel(f);
                }
            ]);
        } else if (params.mode === "admin") {
            if (returnClassOnly) {
                return AdministratorScene;
            }

            AdministratorScene.open(f);
        } else if (params.mode === "editor") {
            if (returnClassOnly) {
                return EditorScene;
            }

            cleverapps.focusManager.compound(f, [
                AdminEpisodes.ResetAction,

                function (f) {
                    LevelManager.getInstance().editLevel(f);
                }
            ]);
        } else if (params.mode === "admin_locations") {
            if (returnClassOnly) {
                return AdminLocationsScene;
            }
            AdminLocationsScene.open(f);
        } else if (params.mode === "editor_location") {
            if (returnClassOnly) {
                return EditorLocationScene;
            }
            EditorLocationScene.open(f);
        }
    };
}());

AdministratorScene.MODES = {
    HORIZONTAL: 0,
    VERTICAL: 1
};

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    ADMIN_TEXT_COLOR: new cc.Color(89, 144, 221, 255)
});

AdministratorScene.WHITELIST = [
    "alena_su",
    "aleksey_ov",
    "alexander_br",
    "alexander_fe",
    "alexandra_ly",
    "alexandra_ma",
    "alexandrina_re",
    "alina_ma",
    "alyona_pa",
    "anastasia_pi",
    "anatoly_me",
    "andrey_ka",
    "andrey_po",
    "anna_de",
    "arseniy_mo",
    "bogdan_fi",
    "christina_go",
    "christina_ka",
    "daria_ch",
    "denis_al",
    "denis_ku",
    "dina_go",
    "dmitrii_ev",
    "dmitry_er",
    "dmitriy_ka",
    "elena_so",
    "elizabeth_bo",
    "eugene_ni",
    "evgeny_se",
    "ivan_mo",
    "ivan_mok",
    "ivan_sh",
    "maria_ko",
    "michael_ti",
    "nail_kh",
    "natalia_sa",
    "nikita_la",
    "olga_er",
    "pavel_fr",
    "peter_is",
    "reda_ab",
    "roman_kh",
    "rustam_nu",
    "sergei_bo",
    "sergey_se",
    "stepan_py",
    "stepan_te",
    "svetlana_ma",
    "viacheslav_ro",
    "viktoriya_ig",
    "vitaly_be",
    "vlad_ra",
    "vsevolod_ca",
    "maksim_si",

    // yatzy
    "anastasia_la",
    "denis_kl",
    "evgeniya_ka",
    "michael_me",
    "svyatoslav_iv",
    "veronika_sh"
];
