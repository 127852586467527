/**
 * Created by Ivan on 09.12.2024
 */

cleverapps.override(cleverapps.EVENTS, {
    STICKERS_COLLECTIONS: "stickers_cl_",
    STICKER_OPEN: "sticker_",
    STICKERS_BOOK_WINDOW_OPEN: "stickers_window",
    STICKERS_SHOP_OPEN: "stickers_shop",
    SPENT: {
        STICKERS_1: "stickerspack1",
        STICKERS_2: "stickerspack2",
        STICKERS_3: "stickerspack3"
    }
});