/**
 * Created by olga on 24.01.2024
 */

var ChainSaleLot = cc.Scale9Sprite.extend({
    ctor: function (options) {
        this.stage = options.stage;
        this.logic = options.logic;
        this.window = options.window;

        this.product = this.logic.getProduct(this.stage);
        if (this.product) {
            cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);
        }

        this._super(bundles.chain_sale.frames.paid_tile_bg);
        this.opened = this.logic.isOpened(this.stage);

        var styles = cleverapps.styles.ChainSaleLot;
        this.setContentSize2(styles.size);
        this.setCascadeOpacityEnabledRecursively(true);

        this.createReward();
        this.createButton();
        if (!this.logic.isLast(this.stage)) {
            this.createArrow();
        }

        this.particles = new cleverapps.Spine(bundles.chain_sale.jsons.particles);
        this.particles.setPositionRound(styles.particles);
        this.addChild(this.particles, 1);

        this.logic.onLotBought[this.stage] = this.createListener(this.receiveRewards.bind(this));
        if (this.opened) {
            this.open(true);
        }
    },

    updateButton: function () {
        if (connector.payments.isConnected() || !this.product) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    createArrow: function () {
        var styles = cleverapps.styles.ChainSaleLot.arrow;
        var arrow = this.arrow = new cc.Sprite(bundles.chain_sale.frames.arrow_png);
        this.arrow.setPositionRound(styles.positions);
        this.arrow.setRotation(styles.rotation[cleverapps.resolution.mode]);
        this.addChild(arrow);
    },

    open: function (silent, callback) {
        this.opened = true;
        cleverapps.UI.onClick(this, this.buyProduct.bind(this), { interactiveScale: false });
        this.setSpriteFrame(bundles.chain_sale.frames.free_tile_bg);
        this.rewardBg.setSpriteFrame(bundles.chain_sale.frames.free_rewards_bg);
        this.particles.setAnimationAndIdleAfter(this.getParticlesName("open"), this.getParticlesName("animation"));

        if (!silent) {
            this.lock.replaceParentSamePlace(this, { keepScale: true });
            this.lock.setLocalZOrder(10);
            this.lock.setAnimation(0, "open", false);
            this.lock.setCompleteListenerRemove();

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    this.createButton();
                    callback && callback();
                }.bind(this))
            ));
        }
    },

    createReward: function () {
        var styles = cleverapps.styles.ChainSaleLot.reward;
        var bg = this.rewardBg = cleverapps.UI.createScale9Sprite(bundles.chain_sale.frames.paid_rewards_bg);
        bg.setContentSize2(styles.size);
        bg.setPositionRound(styles.positions);
        this.addChild(bg);

        var rays = bg.rays = new cleverapps.Spine(bundles.chain_sale.jsons.rays);
        this.rewardBg.rays.setAnimation(0, this.window.isVertical() ? "vertical" : "animation", true);
        bg.addChild(rays);
        rays.setPositionRound(bg.width / 2, bg.height / 2);

        var rewardComponent = this.rewardComponent = new RewardsListComponent(this.logic.getReward(this.stage), {
            columns: "auto",
            fitToBox: styles.fitToBox[cleverapps.resolution.mode],
            font: cleverapps.styles.FONTS.CHAIN_SALE_REWARD,
            textDirection: cleverapps.UI.VERTICAL,
            margin: styles.margin[cleverapps.resolution.mode],
            textMargin: styles.textMargin,
            event: cleverapps.EVENTS.EARN.PURCHASE
        });
        rewardComponent.setCascadeOpacityEnabledRecursively(true);
        bg.addChild(rewardComponent);
        rewardComponent.setPositionRound(bg.width / 2, bg.height / 2);
    },

    createButton: function () {
        if (this.button) {
            this.button.removeFromParent(true);
        }
        var styles = cleverapps.styles.ChainSaleLot.button;

        var text = this.product ? this.product.getCurrentPrice() : "FREE";
        if (this.logic.needToWatchAds(this.stage)) {
            text = "## Watch";
        }
        var textNode = new TextWithIcon(text, { font: this.window.isVertical() ? cleverapps.styles.FONTS.CHAIN_SALE_BUTTON_VERTICAL : cleverapps.styles.FONTS.CHAIN_SALE_BUTTON });

        var button = this.button = new cleverapps.UI.Button({
            content: textNode,
            type: this.opened ? {
                button_png: bundles.chain_sale.frames.yellow_button || bundles.buttons.frames.blue_button_png,
                button_on_png: bundles.chain_sale.frames.yellow_button_on || bundles.buttons.frames.blue_button_on_png,
                button_off_png: bundles.buttons.frames.button_disabled_png,
                SKIP_RESOLUTION: styles.SKIP_RESOLUTION ? styles.SKIP_RESOLUTION : undefined
            } : undefined,
            onClicked: this.buyProduct.bind(this),
            width: styles.size[cleverapps.resolution.mode].width,
            height: styles.size[cleverapps.resolution.mode].height
        });
        button.setPositionRound(styles.positions);
        button.setCascadeOpacityEnabledRecursively(true);
        this.addChild(button);

        if (!this.opened) {
            var lock = this.lock = new cleverapps.Spine(bundles.chain_sale.jsons.lock_json);
            lock.setAnimation(0, "idle", false);
            button.addChild(lock);
            lock.setPositionRound(styles.lock);
        }

        this.updateButton();
    },

    buyProduct: function () {
        if (this.opened) {
            this.logic.buyLot(this.stage);
        }
    },

    receiveRewards: function () {
        this.opened = false;

        this.rewardComponent.receiveRewards();
        this.onBuyAnimation();
    },

    hideAnimation: function () {
        var offset = cc.p(-this.width, this.height);
        this.window.isVertical() ? offset.x = 0 : offset.y = 0;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 0.95).easing(cc.easeOut(1.5)),
            new cc.ScaleTo(0.2, 1.03).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.125, 0.99).easing(cc.easeOut(1.5)),
            new cc.ScaleTo(0.1, 1.01).easing(cc.easeIn(1.5)),
            new cc.ScaleTo(0.2, 1).easing(cc.easeOut(1.5)),
            new cc.DelayTime(0.71),
            new cc.Spawn(
                new cc.ScaleTo(0.2, 0.2).easing(cc.easeIn(2)),
                new cc.MoveBy(0.2, offset.x, offset.y)
            ),
            new cc.Hide()
        ));
    },

    addInAnimation: function () {
        this.setVisible(false);
        this.setScale(0.45);
        var baseP = this.getPosition();
        if (this.window.isVertical()) {
            this.setPosition(baseP.x, baseP.y - this.height);
        } else {
            this.setPosition(baseP.x + this.width, baseP.y);
        }
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.45),
            new cc.Show(),
            new cc.Spawn(
                new cc.ScaleTo(0.2, 1),
                new cc.MoveTo(0.2, baseP)
            )
        ));
    },

    appearAnimation: function (delay) {
        this.setVisible(false);
        this.setScale(0.4);
        this.setOpacity(0.3);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.Show(),
            new cc.Spawn(
                new cc.ScaleTo(0.4, 1).easing(cc.easeBackOut()),
                new cc.FadeIn(0.15)
            )
        ));
    },

    getParticlesName: function (name) {
        return name + (this.window.isVertical() ? "_vertical" : "");
    },

    onBuyAnimation: function () {
        this.particles.setAnimation(0, this.getParticlesName("close"), false);
        cleverapps.audio.playSound(bundles.chain_sale.urls.chain_sale_open_effect);
        this.rewardComponent.receiveRewardsAnimation({
            fadeDelay: 0,
            iconAction: function (icon) {
                icon.replaceParentSamePlace(cleverapps.scenes.getMovingNode(icon));
                return AnimationsLibrary.squeezeJump(icon, { baseScale: icon.getScale() });
            }
        });

        var styles = cleverapps.styles.ChainSaleLot;

        this.button.runAction(new cc.Sequence(
            new cc.FadeTo(0.1, 0.3),
            new cc.Hide(),
            new cc.CallFunc(function () {
                var claimed = cleverapps.UI.generateOnlyText("Claimed", cleverapps.styles.FONTS.CHAIN_SALE_CLAIMED);
                claimed.fitTo(this.width * 0.8);
                claimed.setPosition(this.button.getPosition());
                this.addChild(claimed);
                claimed.setOpacity(0);
                claimed.runAction(new cc.FadeIn(0.1));

                var checkmark = new cleverapps.Spine(bundles.chain_sale.jsons.checkmark_json);
                checkmark.setAnimation(0, "open", false);
                checkmark.setPositionRound(styles.checkmark);
                this.addChild(checkmark);
            }.bind(this))
        ));
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    CHAIN_SALE_CLAIMED_SHADOW: {
        color: cleverapps.styles.COLORS.WHITE,
        direction: cc.size(0, -2),
        blur: 2
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CHAIN_SALE_REWARD: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    CHAIN_SALE_BUTTON: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    CHAIN_SALE_BUTTON_VERTICAL: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    CHAIN_SALE_CLAIMED: {
        size: 45,
        color: new cc.Color(196, 105, 52),
        shadow: cleverapps.styles.DECORATORS.CHAIN_SALE_CLAIMED_SHADOW
    }
});

cleverapps.styles.ChainSaleLot = {
    size: [
        { width: 900, height: 430 },
        { width: 450, height: 730 },
        { width: 450, height: 730 }
    ],

    reward: {
        textMargin: 5,
        margin: [{ x: 50, y: 10 }, { x: 20, y: 10 }, { x: 20, y: 10 }],
        size: [
            { width: 870, height: 290 },
            { width: 420, height: 560 },
            { width: 420, height: 560 }
        ],
        positions: [
            { x: { align: "center" }, y: { align: "center", dy: 53 } },
            { x: { align: "center" }, y: { align: "center", dy: 73 } },
            { x: { align: "center" }, y: { align: "center", dy: 73 } }
        ],
        fitToBox: [
            { width: 850, height: 210 },
            { width: 400, height: 460 },
            { width: 400, height: 460 }
        ]
    },

    button: {
        positions: [
            { x: { align: "center" }, y: { align: "bottom", dy: 28 } },
            { x: { align: "center" }, y: { align: "bottom", dy: 48 } },
            { x: { align: "center" }, y: { align: "bottom", dy: 48 } }
        ],
        size: [
            { width: 320, height: 90 },
            { width: 250, height: 90 },
            { width: 250, height: 90 }
        ],
        lock: {
            x: { align: "right", dx: 20 },
            y: { align: "bottom", dy: -10 }
        }
    },

    checkmark: {
        x: { align: "center" },
        y: { align: "center", dy: 40 }
    },

    particles: {
        x: { align: "center" },
        y: { align: "center" }
    },

    arrow: {
        rotation: [90, 0, 0],
        positions: [
            { x: { align: "center" }, y: { align: "bottom", dy: -40 } },
            { x: { align: "right", dx: 35 }, y: { align: "center" } },
            { x: { align: "right", dx: 35 }, y: { align: "center" } }
        ]
    }
};
