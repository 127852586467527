/**
 * Created by Vladislav on 05.12.2024.
 */

var MinimalDialogueDebug = function (minimal) {
    minimal.addCleaner(this.hide.bind(this));

    this.minimal = minimal;
    this.rects = minimal.listRects();
    this.currentNodes = [];
    this.variants = [];
    this.pickedVariant = undefined;

    this.addVariants(minimal.variants);
    this.setPickedVariant(minimal.pickedVariant);

    this.currentStep = this.variants.indexOf(this.pickedVariant);
    this.drawCurrentStep();

    this.keyboardListener = cc.eventManager.addListener({
        event: cc.EventListener.KEYBOARD,
        onKeyPressed: function (keyCode) {
            if (keyCode === cc.KEY.right) {
                if (this.currentStep < this.variants.length - 1) {
                    this.currentStep++;
                    this.drawCurrentStep();
                }
            }
            if (keyCode === cc.KEY.left) {
                if (this.currentStep > 0) {
                    this.currentStep--;
                    this.drawCurrentStep();
                }
            }
        }.bind(this)
    }, this.minimal);
};

MinimalDialogueDebug.prototype.addVariants = function (variants) {
    this.variants = this.variants.concat(variants);
};

MinimalDialogueDebug.prototype.setPickedVariant = function (pickedVariant) {
    this.pickedVariant = pickedVariant;
};

MinimalDialogueDebug.prototype.drawCurrentStep = function () {
    this.clearCurrentStep();

    var step = this.variants[this.currentStep];

    this.rects.forEach(function (rect) {
        this.currentNodes.push(this.drawRect(rect, new cc.Color(0, 0, 255, 255)));
    }, this);

    step.dialogueRects.forEach(function (rect) {
        var color = step.intersection && step.intersection.rect ? new cc.Color(255, 0, 0, 255) : new cc.Color(0, 255, 0, 255);
        this.currentNodes.push(this.drawRect(rect, color));
    }, this);

    if (step.intersection && step.intersection.rect) {
        this.currentNodes.push(this.drawRect(step.intersection.rect, new cc.Color(255, 0, 0, 255), new cc.Color(255, 0, 0, 255)));
    }
};

MinimalDialogueDebug.prototype.clearCurrentStep = function () {
    this.currentNodes.forEach(function (node) {
        node.removeFromParent();
    });

    this.currentNodes = [];
};

MinimalDialogueDebug.prototype.drawRect = function (rect, color, fillColor) {
    fillColor = fillColor || new cc.Color(0, 0, 0, 0);

    var node = new cc.DrawNode();
    node.setAnchorPoint(0, 0);
    node.setContentSize2(rect.width, rect.height);
    node.setPositionRound(rect.x, rect.y);
    node.drawRect(cc.p(0, 0), cc.p(rect.width, rect.height), fillColor, 3, color);
    node.setLocalZOrder(1000);
    cleverapps.scenes.getRunningScene().addChild(node);

    return node;
};

MinimalDialogueDebug.prototype.hide = function () {
    cc.eventManager.removeListener(this.keyboardListener);
    delete this.keyboardListener;

    this.clearCurrentStep();
};

MinimalDialogueDebug.create = function (minimal) {
    var debugInfo = new MinimalDialogueDebug(minimal);

    cleverapps.flags.on("change:debugMinimal", function () {
        debugInfo.hide();
    }, minimal);
};

MinimalDialogueDebug.initialize = function () {
    cleverapps.flags.on("change:debugMinimal", function () {
        var minimal = $(MinimalDialogue).get();

        if (minimal) {
            if (cleverapps.flags.debugMinimal) {
                MinimalDialogueDebug.create(minimal);
            } else if (minimal.debugMinimal) {
                minimal.debugMinimal.hide();
                delete minimal.debugMinimal;
            }
        }
    });
};