/**
 * Created by mac on 1/30/20
 */

cleverapps.SkinManager.SKINS.xmas = {
    bundle: "xmas",
    slots: {
        windowDecors: function (options) {
            var decors = cleverapps.clone(options.decors || {}, true);

            decors["xmas_window_top"] = true;
            decors["xmas_window_title_right"] = true;
            decors["xmas_window_title_left"] = true;
            decors["xmas_window_right_top"] = true;
            decors["xmas_window_right_bottom"] = true;
            decors["xmas_window_left_bottom"] = true;
            decors["xmas_window_left_top"] = true;
            decors["xmas_window_alternative_title_right"] = true;
            decors["xmas_window_alternative_title_left"] = true;

            if (cleverapps.config.ui === "tropical") {
                decors["decor_title_right"] = false;
                decors["decor_title_left"] = false;
                decors["window_liana_top_left"] = false;
                decors["window_liana_top_right"] = false;
                decors["window_liana_left"] = false;
                decors["window_liana_right"] = false;
                decors["window_liana_bottom_left"] = false;
                decors["window_liana_bottom_right"] = false;
            }

            if (cleverapps.config.ui === "wooden" && (typeof OrdersWindow !== "undefined" && options.window instanceof OrdersWindow)) {
                decors["xmas_window_right_top"] = false;
                decors["xmas_window_right_bottom"] = false;
                decors["xmas_window_left_bottom"] = false;
                decors["xmas_window_left_top"] = false;
            }

            if (cleverapps.config.name === "mergecraft"
                && ((typeof BuildPassWindow !== "undefined" && options.window instanceof BuildPassWindow) 
                || (typeof SalePassWindow !== "undefined" && options.window instanceof SalePassWindow))
                || (typeof ExpeditionPassWindow !== "undefined" && options.window instanceof ExpeditionPassWindow)) {
                decors["xmas_window_right_top"] = false;
                decors["xmas_window_left_top"] = false;

                decors["xmas_window_right_top_bgonly"] = true;
                decors["xmas_window_left_top_bgonly"] = true;
            }

            if (cleverapps.config.ui === "riddles" && (typeof UnitsLibraryWindow !== "undefined" && options.window instanceof UnitsLibraryWindow)) {
                decors["xmas_window_left_bottom"] = false;
            }

            return decors;
        },
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.xmas_menubar;
        },
        menuBarLevelItem: function () {
            return cleverapps.styles.SceneDecors.xmas_menubar_level;
        },
        sidebarBg: function () {
            return bundles.sidebar.frames.xmas_sidebar_bg;
        },
        sidebarIcon: function () {
            return cleverapps.styles.SceneDecors.xmas_sidebar;
        },
        toolbarDecors: function () {
            return cleverapps.styles.SceneDecors.xmas_toolbar;
        },
        controlButton: function () {
            return cleverapps.styles.SceneDecors.xmas_control_buttons;
        }
    }
};

if (["heroes", "riddles"].includes(cleverapps.config.name)) {
    Object.assign(cleverapps.SkinManager.SKINS.xmas.slots, {
        A_hero_spine_json: bundles.xmas.jsons.A_hero_spine_json,
        B_hero_spine_json: bundles.xmas.jsons.B_hero_spine_json,
        C_hero_spine_json: bundles.xmas.jsons.C_hero_spine_json,
        D_hero_spine_json: bundles.xmas.jsons.D_hero_spine_json,
        E_hero_spine_json: bundles.xmas.jsons.E_hero_spine_json,
        W_hero_spine_json: bundles.xmas.jsons.W_hero_spine_json
    });
}