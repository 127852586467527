/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["hoseMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        return cleverapps.meta.getMainObject().canMoveNext();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "hoseMoveNext",
            actions: [
                function (f) {
                    cleverapps.Plot.onFinishEpisode(f, {
                        episodeNo: cleverapps.meta.selectedLocationId()
                    });
                },
                function (f) {
                    cleverapps.meta.moveNextLocation();
                    f();
                },
                function (f) {
                    var scene = cleverapps.scenes.getRunningScene();
                    var floatPage = cleverapps.meta.selectedLocationId();

                    if (cleverapps.meta.isCompleted()) {
                        floatPage += 0.4;
                    }

                    scene.gotoNextLocation(f, floatPage);
                },
                function (f) {
                    cleverapps.Plot.onStartEpisode(f, {
                        episodeNo: cleverapps.meta.selectedLocationId()
                    });
                }
            ]
        });
    }
};