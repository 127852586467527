/**
 * Created by slava on 10/8/18
 */

var GameSceneBase = cleverapps.FixedWidthScene.extend({
    ctor: function (options) {
        this.options = options || {};

        this._super(options);

        this.styling = this.level ? GameSceneBase.ChooseStyling(this.level) : {};
    },

    onSceneLoaded: function (sceneName) {
        cleverapps.environment.levelNo = this.level.levelNo;
        cleverapps.environment.episodeNo = this.level.episodeNo;
        cleverapps.environment.setScene(sceneName || cleverapps.Environment.SCENE_GAME);

        var level = this.level;
        var game = this.game = new Game(level, this.options.gameOptions);

        this._super(sceneName || cleverapps.Environment.SCENE_GAME);

        if (levels.cookieJar && levels.cookieJar.shouldBeVisible()) {
            levels.cookieJar.setVisible();
        }

        if (this.game.competition && cleverapps.isKnockoutGame()) {
            this.playersView = new KnockoutPlayersView(this.game.competition, cleverapps.knockoutGame.rumble.getCurrentRound());
            this.addChild(this.playersView);
        }

        if (game.combo) {
            var comboView = new ComboBarView(game.combo);
            this.addChild(comboView);

            var comboBarControl = new HidingNode(comboView, cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl("comboBar", comboBarControl);
        }

        if (["riddles", "heroes"].indexOf(cleverapps.config.name) !== -1) {
            this.infoPanel = new InfoPanel();
            this.addChild(this.infoPanel);

            if (!level.isTricky()) {
                this.controlsPanel = new ControlsPanel();
                if (!cleverapps.restoreProgress.isAvailable()) {
                    this.addChild(this.controlsPanel);

                    game.on("outcome", function () {
                        this.controlsPanel.hide();
                    }.bind(this), this);
                }
            }
        }

        game.on("animateOutcome", this.animateOutcome.bind(this), this);

        cc.pool.drainAllPools();
        this.game.initPool();

        if (this.game.levelWithCoins) {
            var rewardGoldView = new RewardGoldView();
            this.addChild(rewardGoldView);

            var rewardGoldControl = new HidingNode(rewardGoldView, cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl("rewardElementGold", rewardGoldControl);
        }

        if (this.game.secondaryMission) {
            var cloversView = this.cloversView = new CloversView();
            if (cloversView.withinInfoPanel) {
                this.infoPanel.addChild(cloversView);
            } else {
                this.addChild(cloversView);

                var cloversControl = this.cloversControl = new HidingNode(cloversView);
                cleverapps.focusManager.registerControl(
                    "rewardElementClover",
                    cloversControl,
                    cleverapps.config.type !== "match3" && cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? [cleverapps.Environment.SCENE_GAME] : undefined
                );
            }
        }

        if (comboBarControl || rewardGoldControl || cloversControl) {
            this.horizontalBlocker = new HorizontalBlockerNode(cloversControl || comboBarControl || rewardGoldControl);
            this.addChild(this.horizontalBlocker);
        }

        if (this.level.isRegular() || this.level.isBonusWorldLevel()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_START, {
                hash: this.level.hash
            });
        }

        connector.platform.gameplayStart();

        if (this.game.tournament) {
            var playersView = new TournamentPlayersView(this.game.competition);
            this.addChild(playersView);

            this.playersView = new HidingNode(playersView, cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl("tournamentPlayersView", this.playersView);
        }

        game.on("showMessage", this.onGameMessage.bind(this), this);

        var exclamationView = this.exclamationView = ExclamationViewBase.Create();
        this.addChild(exclamationView, 30);
        exclamationView.setPositionRound(cleverapps.styles.GameScene.exclamation);

        if (typeof FlyingAdView !== "undefined" && !cleverapps.config.wysiwygMode) {
            this.addChild(new FlyingAdView());
        }

        game.on("stop", this.onStop.bind(this), this);
        game.on("outOfMoves", this.outOfMoves.bind(this), this);
    },

    onGameMessage: function (message) {
        var messageView = new GameMessageView(message);
        this.addChild(messageView);
    },

    onStop: function () {
    },

    outOfMoves: function () {
    },

    calcHash: function () {
        return RecursiveHasher(this.level.content, ["episodeNo", "levelNo", "version", "hard", "paint", "extra", "gold", "bundles"]);
    },

    introControls: function () {
        return ["panel_info"];
    },

    playIntroActions: function (f, silent) {
        this.game.playIntro(f, silent);
    },

    _closeAction: function () {
        if (BackToAdminOrEditorAction.call(this) || !this.game) {
            return;
        }

        cleverapps.focusManager.display({
            focus: "GameSceneCloseAction",
            action: function (f) {
                this.game.leave(f);
            }.bind(this)
        });
    },

    animateOutcome: function () {
        if (cleverapps.focusManager.isFocused()) {
            return;
        }

        cleverapps.focusManager.display({
            focus: "ChangeOutcome",
            control: this.introControls(),

            actions: [
                VictoryMessageAction,

                function (f) {
                    if (this.game.needBonusByLeftMoves()) {
                        this.tapToSkip = this.createTapToSkip();

                        this.game.bonusByLeftMoves(f);
                    } else {
                        f();
                    }
                }.bind(this),

                function (f) {
                    if (this.tapToSkip) {
                        this.tapToSkip.hide();
                        delete this.tapToSkip;
                    }

                    f();
                }.bind(this),

                function (f) {
                    this.game.stop();
                    connector.platform.gameplayStop();
                    f();
                }.bind(this),

                SaveFinalResultsAction.bind(this),

                HideAnimations.bind(this),

                function (f) {
                    this.game.boatswain.prepareNext();
                    f();
                }.bind(this),

                InterstitialAction.bind(this),
                ShowOutcomeWindow.bind(this),
                UpdateScoreAction.bind(this),
                ReportLevelOutcomeAction.bind(this),

                RateWindowAction.bind(this),
                NewLeagueWindowAction.bind(this),
                MinigameFinishAction.bind(this),

                BoatswainAction.bind(this),
                
                PurgeOldGameAction.bind(this)
            ]
        });
    },

    getBackgroundStyles: function () {
        var options = cleverapps.skins.getSlot("gameSceneBg") || this.styling;

        var urls = bundles[options.bundle].urls;
        options.backgroundId = urls[options.backgroundId] ? options.backgroundId : "background";
        options.animation = bundles[options.bundle].jsons.background_spine || options.animation;

        return options;
    },

    getAudioStyles: function () {
        return {
            res: cleverapps.skins.getSlot("gameAudio") || this.styling.music,
            fadeIn: cleverapps.styles.GameScene.sound.fadeIn,
            delay: cleverapps.config.wysiwygMode ? 0 : cleverapps.styles.GameScene.sound.delay
        };
    },

    setupChildren: function () {
        this._super();

        this.exclamationView && this.exclamationView.setPositionRound(cleverapps.styles.GameScene.exclamation);
    },

    listBundles: function () {
        var level = this.level;
        var styling = this.styling;

        var gameBundles = ["game"];

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            gameBundles.push("game_background_horizontal");
        } else {
            gameBundles.push("game_background_vertical");
        }

        if (styling) {
            gameBundles.push(styling.bundle);
        }

        if (level) {
            gameBundles.push(level.getImageBundle());

            var cellSkins = level.getLevelCellSkins();
            if (cellSkins) {
                Object.values(cellSkins).forEach(function (skin) {
                    if (bundles[skin]) {
                        var baseSkin = skin.substring(0, skin.indexOf("_reskin"));
                        var baseInd = gameBundles.indexOf(baseSkin);
                        gameBundles.splice(baseInd, baseInd > 0 ? 1 : 0, skin);
                    }
                });
            }

            if (cleverapps.config.rpg) {
                gameBundles.push("enemies");
            }
        }

        if (["riddles", "heroes"].includes(cleverapps.config.name) && this.level.isTricky()) {
            gameBundles.push("danger_component");
        }

        if (cleverapps.config.subtype === "stacks") {
            gameBundles.push("extra_" + cleverapps.settings.language);
        }

        if (bundles["letters_" + cleverapps.settings.language]) {
            gameBundles.push("letters_" + cleverapps.settings.language);
        }

        return gameBundles.filter(function (bundle) {
            return bundle && bundles[bundle];
        });
    },

    createBonusChest: function () {
        var bonusChest = this.bonusChest = new ChestVictoryAnimation();
        bonusChest.setPositionRound(cleverapps.styles.ChestVictoryAnimation.chestPosition);

        this.addChild(bonusChest);

        return bonusChest;
    },

    createTapToSkip: function () {
        var tapToSkip = new TapToSkip({
            text: "TapToSkip"
        });
        tapToSkip.setPositionRound(cleverapps.styles.TapToSkip);
        tapToSkip.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 2);

        this.game.on("onSkip", function () {
            delete this.tapToSkip;
        }.bind(this));

        tapToSkip.show();
        this.addChild(tapToSkip);

        return tapToSkip;
    }
});

GameSceneBase.ChooseStyling = function (level) {
    if (bundles[level.bundleId()] && bundles[level.bundleId()].urls.background) {
        return {
            bundle: level.bundleId()
        };
    }

    if (cleverapps.environment.isEditorScene() && cleverapps.styles.GameScene.STYLING.editor) {
        return cleverapps.styles.GameScene.STYLING.editor;
    }

    var styling = cleverapps.styles.GameScene.STYLING;

    var data = styling.regular[Math.abs(level.getHumanReadableNumber()) % styling.regular.length];

    if (level.isBonusRound() || level.isBonus()) {
        data = styling.bonus;
    } else if (level.isHard() || cleverapps.isRumble() && cleverapps.knockoutGame.rumble.getCurrentRound().isLast()) {
        data = styling.hard;
    } else if (level.isTricky()) {
        data = styling.tricky;
    }

    data = cleverapps.clone(data);

    var bundle = data.bundle;
    if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
        bundle = bundle.replace("_vertical", "_horizontal");
    }
    data.bundle = bundles[bundle] ? bundle : data.bundle.replace("_vertical", "_" + cleverapps.config.orientation);

    if (level.isBonusWorldLevel()) {
        var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD);
        if (mission) {
            data.bundle = mission.bonusWorld.backgroundBundleId();
            data.music = bundles[data.bundle].urls.game_music || bundles[data.bundle].urls.music;
            data.skin = undefined;
        }
    }

    return data;
};

cleverapps.styles.GameScene = {
    exclamation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    actor: undefined,
    startAnimation: undefined,

    sound: {
        delay: 1
    },

    debugLevelNo: {
        x: { align: "right", dx: -100 },
        y: { align: "bottom", dy: 100 }
    }
};

cleverapps.styles.GameScene.STYLING = {
    bonus: {
        backgroundId: "background_bonus",
        bundle: "game_background_vertical",
        music: bundles.game.urls.background_music_game
    },

    hard: {
        backgroundId: "background_hard",
        bundle: "game_background_vertical",
        music: bundles.game.urls.background_music_hard
    },

    regular: [
        {
            bundle: "game_background_vertical",
            music: bundles.game.urls.background_music_game
        }
    ]
};

if (["wordsoup", "olympics"].indexOf(cleverapps.config.name) !== -1) {
    cleverapps.styles.GameScene.STYLING.bonus.patternId = "background_bonus";
}

if (["wordsoup", "olympics", "differences"].indexOf(cleverapps.config.name) !== -1) {
    cleverapps.styles.GameScene.STYLING.hard.patternId = "background_hard";
    cleverapps.styles.GameScene.STYLING.regular[0].patternId = "background";
}

if (["hustlemerge"].indexOf(cleverapps.config.name) !== -1) {
    cleverapps.styles.GameScene.STYLING.regular[0].topAnimation = bundles.merge.jsons.bg_animation_top;
    cleverapps.styles.GameScene.STYLING.regular[0].parallax = true;
    cleverapps.styles.GameScene.STYLING.regular[0].scale9 = bundles.merge.frames.bgScale9;
}

(function () {
    if (bundles.editor && bundles.editor.urls.background) {
        cleverapps.styles.GameScene.STYLING.editor = {
            bundle: "editor"
        };
    }

    var example = cleverapps.styles.GameScene.STYLING.regular[0];

    for (var i = 2; ; i++) {
        if (!bundles.game_background_vertical) {
            if (!bundles.game_background_horizontal || !bundles.game_background_horizontal.urls["background_" + i]) {
                break;
            }
        } else if (!bundles.game_background_vertical.urls["background_" + i]) {
            break;
        }

        cleverapps.styles.GameScene.STYLING.regular.push({
            backgroundId: "background_" + i,
            bundle: example.bundle,
            music: example.music,
            patternId: example.patternId
        });
    }
}());
