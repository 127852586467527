/**
 * Created by olga on 24.01.2024
 */

var ChainSaleLogic = function (offer) {
    cleverapps.EventEmitter.call(this);
    this.offer = offer;
    this.reward = Offers[offer.type].reward || RewardsConfig.ChainSale;

    this.onLotBought = {};
    this.onWindowShowUpFinished = function () {};
};

ChainSaleLogic.prototype = Object.create(cleverapps.EventEmitter.prototype);
ChainSaleLogic.prototype.constructor = ChainSaleLogic;

ChainSaleLogic.prototype.buyLot = function (stage) {
    if (!this.isOpened(stage) || this.isSold(stage)) {
        return;
    }

    var product = this.getProduct(stage);
    if (product) {
        product.buy(this.processBought.bind(this), { noRewardWindow: true });
    } else if (this.needToWatchAds(stage)) {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.CHAIN, this.processBought.bind(this, true));
    } else {
        this.processBought(true);
    }
};

ChainSaleLogic.prototype.getProduct = function (stage) {
    var hardPrice = this.reward[stage].hardPrice;
    if (hardPrice) {
        return Product.CreateByHardPrice(this.reward[stage].hardPrice, {
            type: "chain_sale",
            offer: this.offer.type,
            stage: stage
        });
    }
};

ChainSaleLogic.prototype.processBought = function (success) {
    if (!success) {
        return;
    }

    var stage = this.getStage();

    this.offer.incStage();
    this.trigger("changeStage");
    this.checkIfComplete();

    if (this.onLotBought[stage]) {
        this.onLotBought[stage]();
    }
};

ChainSaleLogic.prototype.checkIfComplete = function () {
    if (this.isAllSold()) {
        this.offer.complete();
    }
};

ChainSaleLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

ChainSaleLogic.prototype.currentLots = function () {
    var stage = this.getStage();
    var stages = [];

    for (var i = stage; i < stage + ChainSaleLogic.VISIBLE_LOTS; i++) {
        if (this.reward[i]) {
            stages.push(i);
        }
    }
    return stages;
};

ChainSaleLogic.prototype.isLast = function (stage) {
    return stage === this.reward.length - 1;
};

ChainSaleLogic.prototype.isOpened = function (stage) {
    return stage <= this.getStage();
};

ChainSaleLogic.prototype.isSold = function (stage) {
    return stage < this.getStage();
};

ChainSaleLogic.prototype.getReward = function (stage) {
    return this.reward[stage].reward;
};

ChainSaleLogic.prototype.needToWatchAds = function (stage) {
    return this.reward[stage].watchAds && cleverapps.rewardedAdsManager.isRewardedAvailable();
};

ChainSaleLogic.prototype.isAllSold = function () {
    return this.currentLots().length === 0;
};

ChainSaleLogic.prototype.getTimeLeft = function () {
    return Math.max(0, (this.offer.started || 0) + cleverapps.parseInterval(Offers[this.offer.type].duration) - Date.now());
};

ChainSaleLogic.prototype.isIconAvailable = function () {
    return true;
};

ChainSaleLogic.prototype.hasIconAttention = function () {
    return false;
};

ChainSaleLogic.RestoreActions = function (product, context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer || !offer.logic.isOpened(context.stage) || offer.logic.isSold(context.stage)) {
        return;
    }

    return [
        function (f) {
            offer.logic.onWindowShowUpFinished = cleverapps.once(function () {
                consume();
                offer.logic.processBought(true);
            });

            new ChainSaleWindow(offer);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

ChainSaleLogic.VISIBLE_LOTS = 3;

Restorable.BY_TYPE["chain_sale"] = new Restorable({
    location: Restorable.LocationOfferExpedition,
    listActions: ChainSaleLogic.RestoreActions
});