/**
 * Created by andrey on 28.03.2024.
 */

var MetricsLogger = function () {
    this.batch = { names: [], params: [] };

    this.sendEvents = cleverapps.accumulate(0, this._sendEvents.bind(this));

    if (!cleverapps.config.metricsApiURL) {
        console.log("Metrics logger disabled - metricsApiURL is not defined");
    }
};

MetricsLogger.prototype.logEvent = function (name, params) {
    if (!cleverapps.config.metricsApiURL) {
        return;
    }

    if (name.length > 50) {
        cleverapps.throwAsync("Event name length is more than 50 characters: " + name);

        name = name.substring(0, 50);
    }

    this.batch.names.push(name);
    this.batch.params.push(params);
    
    this.sendEvents();
};

MetricsLogger.prototype._sendEvents = function () {
    var events = this.batch.names.join(",");
    console.log("Sending events - " + events);

    var data = this.getBaseParameters();
    data.events = events;
    data.params = this.batch.params;

    cleverapps.RestClient.post(cleverapps.config.metricsApiURL + "/events/", data, function () {
        // console.log("Success event - " + data.events.join(','));
    }, function () {
        console.log("Failure event - " + events);
    }, {
        ignoreNoRest: cleverapps.config.importMode
    });

    this.batch = { names: [], params: [] };
};

MetricsLogger.prototype.getBaseParameters = function () {
    var params = {};

    if (cleverapps.user && connector.platform.getUserID()) {
        params.id = connector.platform.getUserID();
    }

    if (cleverapps.environment) {
        if (cleverapps.environment.levelNo !== undefined) {
            params.level = cleverapps.environment.levelNo;
        }

        if (cleverapps.environment.episodeNo !== undefined) {
            params.episode = cleverapps.environment.episodeNo;
        }
    }

    if (cleverapps.config && cleverapps.config.type === "merge") {
        if (cleverapps.user) {
            params.level = cleverapps.user.level;
        }
    }

    var level = Game.currentGame && Game.currentGame.level;
    if (level) {
        if (level.hash) {
            params.hash = level.hash;
        }
    }

    params.source = connector.info.source;

    return params;
};