/**
 * Created by slava on 24.03.17.
 */

var Hose = function (locationId) {
    BaseLocation.call(this, locationId);
};

Hose.prototype = Object.create(BaseLocation.prototype);
Hose.prototype.constructor = Hose;

Hose.prototype.load = function (stored) {
    this.stars = stored.stars || 0;
};

Hose.prototype.getInfo = function () {
    return {
        stars: this.stars
    };
};

Hose.prototype.changeProgress = function (stars) {
    this.setProgress(this.stars + stars);
};

Hose.prototype.setProgress = function (stars) {
    this.stars = stars;
    this.save();
};

Hose.prototype.getProgress = function () {
    return this.stars;
};

Hose.prototype.gamePlayed = function (outcome) {
    if (outcome === GameBase.OUTCOME_VICTORY && !cleverapps.meta.isCompleted()) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

        cleverapps.meta.getMainObject().changeProgress(1);
    }
};

Hose.prototype.getBackgroundBundle = function () {
    if (!bundles.main_background_vertical || !bundles.main_background_horizontal) {
        return;
    }
    return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "main_background_vertical" : "main_background_horizontal";
};

Hose.prototype.canMoveNext = function () {
    return this.stars >= Hose.LEVELS_PER_LOCATION && !cleverapps.meta.isCompleted();
};

Hose.LEVELS_PER_LOCATION = 15;

Hose.ListAvailableLocations = function () {
    var ids = [];
    var id = 0;

    var exist = function () {
        var bundleName = "episode_" + id;

        if (cleverapps.config.type === "board" && cleverapps.settings.language !== "en") {
            bundleName += "_" + cleverapps.settings.language;
        }

        return bundles[bundleName] && !bundles[bundleName].meta.debug;
    };

    while (exist()) {
        ids.push(id);
        id++;
    }

    return ids;
};

Hose.ListActiveLocations = function (currentLocationId) {
    return [currentLocationId];
};

Hose.NextLocationId = function (locationId) {
    return locationId + 1;
};
