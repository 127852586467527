/**
 * Created by vladislav on 1/18/2024
 */

var CrazyLogic = function () {
    PlatformLogic.call(this);
};

CrazyLogic.prototype = Object.create(PlatformLogic.prototype);
CrazyLogic.prototype.constructor = CrazyLogic;
