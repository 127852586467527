/**
 * Created by Andrey Popov on 24.03.21
 */

cleverapps.setRestDummies = function (enabled) {
    var dummifyFunc = function (ClassObj, funcName, dummyFunc) {
        if (ClassObj.prototype && ClassObj.prototype[funcName]) {
            ClassObj.prototype["super_" + funcName] = ClassObj.prototype[funcName];
            ClassObj.prototype[funcName] = dummyFunc;
        } else if (ClassObj[funcName]) {
            ClassObj["super_" + funcName] = ClassObj[funcName];
            ClassObj[funcName] = dummyFunc;
        }
    };
    var restoreFunc = function (ClassObj, funcName) {
        if (ClassObj.prototype && ClassObj.prototype["super_" + funcName]) {
            ClassObj.prototype[funcName] = ClassObj.prototype["super_" + funcName];
            delete ClassObj.prototype["super_" + funcName];
        } else if (ClassObj["super_" + funcName]) {
            ClassObj[funcName] = ClassObj["super_" + funcName];
            delete ClassObj["super_" + funcName];
        }
    };

    if (enabled) {
        dummifyFunc(connector.payments, "getSubscriptionStatus", function (options, callback) {
            callback();
        });
        if (typeof UserClan !== "undefined") {
            dummifyFunc(UserClan, "loadRequest", function (onSuccess) {
                onSuccess(cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.clan);
            });
            dummifyFunc(ClanMessages, "loadMessagesRequest", function (onSuccess) {
                onSuccess(cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.clan);
            });
        }
        if (typeof KnockoutGame !== "undefined") {
            dummifyFunc(KnockoutGame, "checkConnection", function (f) {
                f();
            });
        }
    } else {
        restoreFunc(connector.payments, "getSubscriptionStatus");
        if (typeof UserClan !== "undefined") {
            restoreFunc(UserClan, "loadRequest");
            restoreFunc(ClanMessages, "loadMessagesRequest");
        }
        if (typeof KnockoutGame !== "undefined") {
            restoreFunc(KnockoutGame, "checkConnection");
        }
    }
};

cleverapps.importSnapshot = function () {
    if (connector.platform.oneOf(connector.WECHAT)) {
        return;
    }

    var snapshotId = window.location.pathname.substring(1);

    if (/^[0-9a-z]*$/.test(snapshotId) === false) {
        snapshotId = undefined;
    }

    if (window.location.hash) {
        var params = cleverapps.getRequestParameters(window.location.hash, true);
        snapshotId = params.snapshot;
    }

    if (typeof Wysiwyg !== "undefined") {
        snapshotId = Wysiwyg.BeforeImportSnapshot(snapshotId);
    }

    if (!snapshotId || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.snapshotToLoad = snapshotId;

    cleverapps.config.importMode = true;
    cleverapps.setRestDummies(true);

    // for TestSocial
    var onUpdateSocial = function () {
        if (connector.social.getStatus() === connector.STATUS_DISABLED) {
            return;
        }

        connector.social.getPlugin().updateSession = function (id) {
            this.id = id;
            this.token = "123";
        };
        connector.social.getPlugin().removeSession = function () {
            this.id = undefined;
            this.token = undefined;
            this.expires = undefined;
        };
        connector.social.getPlugin().loadSession = function () {};
    };

    connector.social.on("pluginChanged", onUpdateSocial);
    onUpdateSocial();

    connector.dataLoader.setEnabled(false);

    DataLoader.prototype.resetClientSession = function () {
        this.setAlerted(true);
        this.setEnabled(false);
        this.setCorrupted(false);
    };

    cleverapps.Synchronizer.prototype._sync = function () {};
};

cleverapps.loadSnapshot = function (snapshotId, callback) {
    console.log("loading snapshot start");

    var snapshot;

    new ActionPlayer([
        function (f) {
            cleverapps.snapshots.getSnapshot(snapshotId, function (loadedSnapshot) {
                snapshot = loadedSnapshot;
                f();
            });
        },

        function (f) {
            if (snapshot) {
                console.log("loading snapshot finished", snapshot.users);

                cleverapps.loadedSnapshot = snapshot;
                cleverapps.loadedSnapshot.snapshotId = snapshotId;

                if (cleverapps.loadedSnapshot.actions) {
                    BannerAd.prototype.canShow = function () {
                        return false;
                    };
                    if (cleverapps.MiniGame) {
                        cleverapps.MiniGame.TIMEOUT = "1 seconds";
                    }
                }

                cleverapps.snapshots.importData(snapshot, f);

                if (cleverapps.playButton) {
                    cleverapps.playButton.update();
                }
            } else {
                f();
            }
        },

        function (f) {
            cleverapps.synchronizer._onSyncProcessed();
            f();
        }
    ]).play(callback);

    cleverapps.flags.norest = true;
    cleverapps.flags.nologin = false;
};

cleverapps.reloadSnapshotScene = function (scene, f) {
    var level = new Level(scene.episodeNo, scene.levelNo);
    var page = cleverapps.travelBook.getPageById(level.meta.expedition) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.rumble = new Rumble();
        cleverapps.knockoutGame.rumble.whenInitialize(function () {
            level.play(f);
        });
        return;
    }

    level.play(f);
};
