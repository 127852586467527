/**
 * Created by Ivan on 17.10.2024
 */

var OpenLetterButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster, scene) {
        this.booster = booster;

        this.shadow = new ShadowLayer();
        this.shadow.setLocalZOrder(OpenLetterButtonBooster.SHADOW_Z);
        
        this.keypadView = scene.keypadView;
        this.boardView = scene.boardView;

        this._super({
            booster: booster,
            styles: cleverapps.styles.OpenLetterButtonBooster
        });

        booster.on("afterExecute", this.createListener(this.afterExecute.bind(this)), this);
    },

    createIcon: function () {
        return new cc.Sprite(bundles.buttons.frames.open_letter_png);
    },

    moveUp: function () {
        this.keypadView.oldZ = this.keypadView.getLocalZOrder();
        this.keypadView.setLocalZOrder(OpenLetterButtonBooster.SHADOW_Z + 1);

        this.boardView.oldZ = this.boardView.getLocalZOrder();
        this.boardView.setLocalZOrder(OpenLetterButtonBooster.SHADOW_Z + 1);
    },

    addMagnifier: function () {
        var styles = cleverapps.styles.OpenLetterButtonBooster.magnifier;
        var scene = cleverapps.scenes.getRunningScene();
        var grid = this.boardView.grid;

        var spine = new cleverapps.Spine(bundles.game.jsons.open_letter_magnifer_json);
        spine.setAnimation(0, "animation", false);
        grid.addChild(spine);

        var sourcePosition = cc.p(scene.width / 2, scene.height / 2);
        spine.setPositionRound(grid.convertToNodeSpace(scene.convertToWorldSpace(sourcePosition)));

        var startPosition = spine.calculatePositionRound(styles.start);
        var finishPosition = spine.calculatePositionRound(styles.finish);

        var movingNode = cleverapps.scenes.getMovingNode();
        spine.replaceParentSamePlace(movingNode);
        var position1 = movingNode.convertToNodeSpace(grid.convertToWorldSpace(startPosition));
        var position2 = movingNode.convertToNodeSpace(grid.convertToWorldSpace(finishPosition));

        spine.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, position1.x, position1.y),
            new cc.MoveTo(0.8, position2.x, position2.y),
            new cc.RemoveSelf()
        ));
    },

    moveDown: function () {
        this.keypadView.setLocalZOrder(this.keypadView.oldZ);
        delete this.keypadView.oldZ;

        this.boardView.setLocalZOrder(this.boardView.oldZ);
        delete this.boardView.oldZ;
    },

    onExecute: function () {
        this.shadow.createShadow();

        this.moveUp();

        this.clickHandler = cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function (touch) {
                var sceneBox = this.keypadView.getSceneBoundingBox();
                var position = cleverapps.scenes.getRunningScene().convertTouchToNodeSpace(touch);
                if (!cc.rectContainsPoint(sceneBox, position)) {
                    this.booster.afterExecute();
                    this.afterExecute(true);
                }
            }.bind(this)
        }, this.shadow);
    },

    afterExecute: function (cancel) {
        this.shadow.deleteShadow();
        cc.eventManager.removeListener(this.clickHandler);

        this.moveDown();

        if (!cancel) {
            this.addMagnifier();
        }

        this.keypadView.views.forEach(function (letter) {
            letter.enable();

            if (letter.animation) {
                letter.animation.removeFromParent();
            }

            if (letter.hoverHandler) {
                letter.hoverHandler.remove();
            }
        });

        this.keypadView.shuffleButton.show();
    }
});

OpenLetterButtonBooster.SHADOW_Z = 11;

cleverapps.styles.OpenLetterButtonBooster = {
    width: 150,
    height: 120,

    position: [
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 620 } },
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 620 } },
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 650 } }
    ],
    phone: {
        scale: 1.0
    },
    content: {
        margin: -20,
        direction: cleverapps.UI.VERTICAL,
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 20 }
        },
        freeBlock: {
            textWidth: 120
        }
    },

    magnifier: {
        start: {
            x: { align: "left", dx: -100 },
            y: { align: "center" }
        },
        finish: {
            x: { align: "right", dx: 100 },
            y: { align: "center" }
        }
    }
};