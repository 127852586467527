/**
 * Created by Andrey Popov 20 apr 2020
 */

var TileButton = cc.Node.extend({
    ctor: function (tileModel, options) {
        this._super();

        this.tileModel = tileModel;
        this.options = options || {};

        var styles = cleverapps.styles.TileButton;

        var button = this.button = new cleverapps.UI.Button({
            text: this.tileModel.getButtonText(),
            width: options.width || styles.width,
            height: options.height || styles.height,
            type: options.type,
            onClicked: options.onClicked.bind(options, tileModel),
            mark: this.tileModel.isAttention()
        });

        if (tileModel.constructor === TileModel) {
            Lottery.addIcon(button, undefined, tileModel.product.itemId === "gold25000");
        }

        if (cleverapps.fallSale && cleverapps.fallSale.isProductForSale(tileModel.product.itemId)) {
            button.addChild(new FallSaleIcon(FallSaleIcon.POSITION_BUTTON));
        }

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(button.getContentSize());
        this.addChild(button);
        button.setPositionRound(styles);
    },

    updateText: function () {
        this.button.setString(this.tileModel.getButtonText());
    },

    enable: function () {
        this.button.enable();

        this.updateText();
    },

    disable: function () {
        this.button.disable();

        this.updateText();
    }
});

cleverapps.styles.TileButton = {
    x: { align: "center" },
    y: { align: "center", dy: -5 },
    width: 220,
    height: 90
};