/**
 * Created by Aleksandr on 15.09.2022.
 */

var SnapshotBar = function () {
    this.isRecording = false;
    this.label = undefined;
    this.isButtonsVisible = true;

    this.onShowLabel = function () {};
    this.onHideLabel = function () {};
    this.onShowButtons = function () {};
    this.onHideButtons = function () {};

    this.onStartRecording = function () {};
    this.onStopRecording = function () {};
};

SnapshotBar.prototype.createView = function () {
    cleverapps.bundleLoader.loadBundle("dev_snapshots", {
        onSuccess: function () {
            new SnapshotBarView();
        }
    });
};

SnapshotBar.prototype.photo = function () {
    cleverapps.snapshots.createSnapshot();
};

SnapshotBar.prototype.record = function () {
    if (!cleverapps.snapshots.canCreate()) {
        return;
    }

    if (!cleverapps.focusManager.isFocused()) {
        cleverapps.focusManager.onFocusLostListenerPlacements();

        if (cleverapps.focusManager.isFocused()) {
            return;
        }
    }

    cleverapps.snapshots.record();
    this.isRecording = true;
    this.onStartRecording();

    this.showLabel("!!rec");
};

SnapshotBar.prototype.stopRecord = function () {
    cleverapps.snapshots.stopRecording();
    this.isRecording = false;
    this.onStopRecording();
    this.removeLabel();
};

SnapshotBar.prototype.createScene = function () {
    cleverapps.wysiwyg = cleverapps.wysiwyg || new Wysiwyg("wysiwyg/tmp", true);
    cleverapps.wysiwyg.hierarchy.createFromTemplate();
};

SnapshotBar.prototype.toggleRecord = function () {
    if (this.isRecording) {
        this.stopRecord();
    } else {
        this.record();
    }
};

SnapshotBar.prototype.hideButtons = function () {
    this.isButtonsVisible = false;
    this.onHideButtons();
};

SnapshotBar.prototype.showButtons = function () {
    this.isButtonsVisible = true;
    this.onShowButtons();
};

SnapshotBar.prototype.toggleButtons = function () {
    if (this.isButtonsVisible) {
        this.hideButtons();
    } else {
        this.showButtons();
    }
};

SnapshotBar.prototype.removeLabel = function () {
    this.onHideLabel();
};

SnapshotBar.prototype.showLabel = function (label) {
    this.label = label;
    this.onShowLabel();
};
