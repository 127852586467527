/**
 * Created by evgenijsenkevic on 28.08.2024
 * */

var EditorLocationScene = cleverapps.FixedWidthScene.extend({
    ctor: function (location) {
        this._super();
        this.location = location;
    },

    onSceneLoaded: function () {
        this._super();
        connector.ads.closeStickyCallback();
        cleverapps.resolution.setContainerPadding();

        cleverapps.administrator.adminLocations.setActive(this.getAdminLocation());

        if (cleverapps.config.editorMode) {
            cleverapps.environment.setScene(cleverapps.Environment.SCENE_EDITOR);
        }

        var location = bundles["location_" + this.location].jsons.map_json.getJson();

        if (cleverapps.config.type === "merge") {
            var page = cleverapps.travelBook.getPageById(location.expedition) || cleverapps.travelBook.getPageById("main");
            cleverapps.travelBook.setCurrentPage(page);
            cleverapps.skins.update();
            
            this.game = new Merge(new Level(location.expedition === "main" ? 0 : location.expedition, 0));
            this.editor = new Editor(MergeOrangery, location);

            Map2d.currentMap.families = Families.listExpeditionCodes(location.expedition);
        } else {
            this.editor = new Editor(HomefixOrangery, location);
        }

        var editorView = new EditorView(this.editor);
        this.addChild(editorView);

        var orangery = Editor.currentEditor.orangery;
        if (orangery) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }
    },

    _closeAction: function () {
        cleverapps.setUrlHash({
            mode: undefined,
            location: undefined
        });

        if (cleverapps.config.adminMode) {
            cleverapps.config.editorMode = false;
            AdminLocationsScene.open();
        }
    },

    initPool: function () {
    },

    addSideBar: function () {
    },

    getBackgroundStyles: function () {
        var SceneCtor = cleverapps.config.type === "merge" ? MergeScene : HomefixScene;
        return SceneCtor.prototype.getBackgroundStyles.apply(this, arguments);
    },

    getAdminLocation: function () {
        var bundleName = "location_" + cleverapps.getRequestParameters(location.hash).location;
        return new AdminLocation(bundleName);
    },

    listBundles: function () {
        var base = this.getAdminLocation().getLocationBundles();

        var all = Editor.BUNDLES.filter(function (bundle) {
            return bundles[bundle];
        });

        return cleverapps.unique(base.concat(all).concat(["editor"]));
    },

    lazyBundles: function () {
        var result = [];

        if (cleverapps.config.type === "merge") {
            var level = new Level(this.location, 0);
            result = result.concat(Families.listLazyBundles(Object.keys(level.families)));
        }

        return result;
    }
});

EditorLocationScene.open = function (f) {
    var params = cleverapps.getRequestParameters(location.hash);
    cleverapps.setUrlHash({ mode: "editor_location" });

    cleverapps.Synchronizer.prototype.showClientSessionExpiredWindow = function () {};

    cleverapps.config.editorMode = true;
    cleverapps.config.adminMode = true;

    cleverapps.adsLimits.updateVolume();

    if (f) {
        cleverapps.scenes.replaceScene(new EditorLocationScene(params.location), f);
    } else {
        cleverapps.focusManager.display({
            focus: "EditorLocationSceneOpen",
            action: function (f) {
                cleverapps.scenes.replaceScene(new EditorLocationScene(params.location), f);
            }
        });
    }
};