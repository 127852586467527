/**
 * Created by r4zi4l on 04.05.2022
 */

var OfferIcon = function (offer) {
    SideBarIcon.call(this, {
        animation: offer.sideBarJson,
        priority: SideBar.PRIORITY_REDUNDANT
    });

    this.offer = offer;

    this.resetState();
};

OfferIcon.prototype = Object.create(SideBarIcon.prototype);
OfferIcon.prototype.constructor = OfferIcon;

OfferIcon.prototype.resetState = function () {
    if (cleverapps.config.editorMode) {
        this.available = false;
        return;
    }

    var logic = this.offer.logic;

    this.available = logic.isIconAvailable();

    if (this.available) {
        this.setLeftTime(logic.getTimeLeft());
        this.setAttention(logic.hasIconAttention());
        this.setTitle(logic.hasIconAttention() ? "Ready" : undefined);
    }
};

OfferIcon.prototype.onPressed = function () {
    cleverapps.offerManager.showOfferWindow(this.offer);
};

OfferIcon.prototype.getForce = function () {
    return this.offer.force;
};