/**
 * Created by spepa on 24.04.2023
 */

var PromotionSaleLot = cc.Scale9Sprite.extend({
    ctor: function (lot, logic, onBought) {
        this.bundle = bundles[logic.offer.bundle];

        this._super(this.bundle.frames.tile_bg, cleverapps.UI.getScale9Rect(this.bundle.frames.tile_bg, cleverapps.UI.Scale9Rect.TwoPixelXY));
        this.setContentSize2(cleverapps.styles.PromotionSaleLot[logic.offer.type]);

        this.lot = lot;
        this.logic = logic;
        this.onBought = onBought;
        this.product = logic.getProduct(lot);

        this.createRewards();
        this.createBadge();
        this.createButton();

        this.updateState();

        cleverapps.paymentsLogic.onChangeStatus(this.updateState.bind(this), this);

        this.logic.onLotBought[this.lot.id] = this.createListener(this.receiveRewards.bind(this));
    },

    createRewards: function () {
        var styles = cleverapps.styles.PromotionSaleLot[this.logic.offer.type].reward;

        var rewardBg = cleverapps.UI.createScale9Sprite(this.bundle.frames.reward_title, cleverapps.UI.Scale9Rect.TwoPixelXY);
        rewardBg.setContentSize2(styles);

        var rewardsList = this.rewardsList = new RewardsListComponent(this.lot.reward, {
            font: cleverapps.styles.FONTS.BONUSSALE_PRODUCT_REWARD,
            columns: "auto",
            fitToBox: {
                width: styles.width,
                height: styles.height
            },
            noPrefix: false,
            reverseRows: true,
            margin: styles.margin,
            textMargin: styles.textMargin,
            iconWrap: styles.icons.wrap,
            textDirection: cleverapps.UI.VERTICAL,
            event: cleverapps.EVENTS.EARN.PURCHASE
        });

        rewardsList.setPositionRound(styles.icons);
        rewardBg.addChild(rewardsList);

        rewardBg.setPositionRound(styles);
        this.addChild(rewardBg);
    },

    createBadge: function () {
        var styles = cleverapps.styles.PromotionSaleLot[this.logic.offer.type].badge;
        var badge = new TileBadge({
            rotation: styles.rotation,
            text: this.lot.profit + "\nFREE",
            largeFont: true
        });
        badge.setPositionRound(styles);
        this.addChild(badge);
    },

    createButton: function () {
        var styles = cleverapps.styles.PromotionSaleLot[this.logic.offer.type].button;
        var button = this.button = new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            onClicked: this.buyProduct.bind(this),
            width: cleverapps.styles.PromotionSaleLot[this.logic.offer.type].button.width,
            height: cleverapps.styles.PromotionSaleLot[this.logic.offer.type].button.height
        });

        button.setPositionRound(styles);
        this.addChild(button);
    },

    buyProduct: function () {
        this.logic.buyLot(this.lot);
    },

    receiveRewards: function () {
        this.rewardsList.receiveRewards();
        this.rewardsList.receiveRewardsAnimation();
    },

    updateState: function () {
        var isConnected = connector.payments.isConnected();

        if (this.logic.isDisabled(this.lot.id) || !isConnected) {
            this.button.disable();

            if (this.clickHandler) {
                this.clickHandler.remove();
                this.clickHandler = undefined;
            }

            if (this.button.lotteryIcon) {
                this.button.lotteryIcon.removeFromParent();
            }
        } else if (isConnected) {
            if (!this.clickHandler) {
                this.clickHandler = cleverapps.UI.onClick(this, this.buyProduct.bind(this));
            }
            this.button.enable();

            Lottery.addIcon(this.button);
        }

        if (this.logic.isSoldLot(this.lot.id)) {
            this.button.setString(Messages.get("SoldOut"));
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUSSALE_PRODUCT_REWARD: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BONUSSALE_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.PromotionSaleLot = {};
cleverapps.styles.PromotionSaleLot[Offers.TYPES.PROMOTION_SALE] = {
    width: 450,
    height: 670,

    reward: {
        width: 410,
        height: 510,

        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 60 },

        margin: {
            x: 10,
            y: 20
        },

        textMargin: -10,

        icons: {
            x: { align: "center" },
            y: { align: "center" },
            wrap: {
                width: 160,
                height: 160
            }
        }
    },

    badge: {
        x: { align: "right", dx: 22 },
        y: { align: "top", dy: -40 },
        rotation: 0
    },

    button: {
        width: 300,

        x: { align: "center" },
        y: { align: "bottom", dy: 26 }
    }
};
cleverapps.styles.PromotionSaleLot[Offers.TYPES.CLPROMOTION_SALE] = cleverapps.styles.PromotionSaleLot[Offers.TYPES.PROMOTION_SALE];

cleverapps.styles.PromotionSaleLot[Offers.TYPES.PERIODIC_PROMOTION_SALE] = cleverapps.overrideStyles(cleverapps.styles.PromotionSaleLot[Offers.TYPES.PROMOTION_SALE], {
    width: 420,
    height: 660,

    badge: {
        x: { align: "right", dx: 60 },
        y: { align: "top", dy: 30 },
        rotation: 27
    },

    reward: {
        width: 370,
        height: 500,
        y: { align: "top", dy: -20 }
    }
}, true);